import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Action, select } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { UserEffects } from './user.effects';

import * as UserActions from '../actions/user.actions';
import * as CartActions from '../../../shop/modules/carts/get-carts/actions/get-carts.actions';
import * as ComparatorActions from '../../../shop/modules/comparator/get-comparator-products/actions/get-comparator-products.actions';
import * as MessagesActions   from '../../../shop/modules/messages/get-messages/actions/get-messages.actions';
import { GetCartOrder } from '@/app/shop/modules/carts/get-cart-order/actions/get-cart-order.actions';
import * as CategoriesActions from '../../../shop/modules/category/get-category-categories/actions/get-category-categories.actions';
import * as CategoryActions from '../../../shop/modules/category/get-category/actions/get-category.actions';
import { GetWishlists } from '@/app/shop/modules/wishlist/get-wishlists/actions/get-wishlists.actions';
// import { LOGIN_MODAL_HEIGHT, LOGIN_MODAL_WIDTH, LoginComponent } from '../components/login/login.component';
// import { McRAIModalOptions } from 'ui';
import { GetSearchHistorySmall } from '@/app/shop/modules/history/get-search-history/actions/get-search-history.actions';

@Injectable()
export class AuthenticateEffects extends UserEffects {

  readonly ROOT_CATEGORY_URL = 'root';
  readonly HOMEPAGE_CATEGORY_URL = 'homepage';

  authenticationFailCount = 0;

  @Effect()
  authenticate: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHENTICATE),
      switchMap(() =>
        this.userService
          .authenticate()
          .pipe(
            map(response => new UserActions.AuthenticateSuccess(response)),
            catchError(response => of(new UserActions.AuthenticateError(response)))
          )
      )
    );

  @Effect()
  authenticateAnonymous: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHENTICATE_ANONYMOUS),
      switchMap((action: UserActions.AuthenticateAnonymous) =>
        this.userService
          .authenticateAnonymous()
          .pipe(
            map(response => {
              if (this.authenticationFailCount >= 1) {
                window.location.reload();
              } else {
                return new UserActions.AuthenticateSuccess(response, action.payload.afterLogout);
              }
            }),
            catchError(response => of(new UserActions.AuthenticateError(response)))
          )
      )
    );

  @Effect()
  authenticateSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHENTICATE_SUCCESS),
      withLatestFrom(
        this.store.pipe(
          select(state => state.sharedSection.activeSection)
        )
      ),
      switchMap((
        [action, activeSection]:
          [UserActions.AuthenticateSuccess, string]
      ) => {
        const actions = [
          new UserActions.SaveToken({ token: action.payload.token })
        ] as Action[];

        /*if (activeSection === 'admin' && action.afterLogout) {
          actions.push(new UserActions.LoginRedirect({url: '/aegisx/login'}));
        }*/

        if (activeSection === 'shop') {
          if (action.afterLogout) {
            if (this.router.url === this.linkService.getHomepageLink()) {
              /*actions.push(
                // new ComparatorActions.GetProducts(),
                // new CartActions.GetCarts(),
                // new GetCartOrder(),
                // new MessagesActions.GetMessages({ id: 'user', page: 1, }),
                // new CategoryActions.GetCategory({ id: this.HOMEPAGE_CATEGORY_URL, }),
                // new CategoriesActions.GetCategoryCategories({
                //   id: this.ROOT_CATEGORY_URL, page: 1
                // }),
                // new GetWishlists(),
                // new GetSearchHistorySmall({ ipp: 5 }),
              );*/
              // If you are HC and don't care about SSR and logout on HP still doesn't work 100%
              // Then and only then enable this
              // window.location.reload();
            } else {
              actions.push(
                new UserActions.LoginRedirect({ url: '/', })
              );
            }
          }/* else {
            actions.push(
              // new ComparatorActions.GetProducts(),
              // new CartActions.GetCarts(),
              // new GetCartOrder(),
              // new MessagesActions.GetMessages({ id: 'user', page: 1, })
            );
          }*/
          // if (action.payload.user.anonym) {
          //   this.modalService.open(LoginComponent, new McRAIModalOptions(
          //     LOGIN_MODAL_WIDTH, LOGIN_MODAL_HEIGHT, { unclosable: true }, false
          //   ));
          // }
        }

        return actions;
      })
    );

  @Effect()
  authenticateError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHENTICATE_ERROR),
      tap(() => {
        this.authenticationFailCount += 1;
      }),
      switchMap(() => {
        if (this.authenticationFailCount > 1) {
          return [
            new UserActions.RemoveToken({
              section: this.sectionService.ALL,
            }),
          ];
        } else {
          return [
            new UserActions.RemoveToken({
              section: this.sectionService.ALL,
            }),
            new UserActions.AuthenticateAnonymous({ })
          ];
        }
      }),
    );
}
