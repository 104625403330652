import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GetClicksEffects } from './effects/get-clicks.effects';
import { BaseHeatmapModule } from '../base-heatmap/base-heatmap.module';
import { OverlayComponent } from './components/overlay/overlay.component';

import * as fromGetClicks from './reducers/get-clicks.reducer';

@NgModule({
  imports: [
    CommonModule,
    BaseHeatmapModule,
    EffectsModule.forFeature([
      GetClicksEffects
    ]),
    StoreModule.forFeature(fromGetClicks.featureName, fromGetClicks.reducer)
  ],
  declarations: [
    OverlayComponent
  ],
  exports: [
    OverlayComponent
  ]
})
export class GetClicksModule { }
