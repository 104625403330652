import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../../../../shared/base/base.service';
import { SearchRecord } from '../../../../../shared/models/history/search-record';
import { AssocArray } from '../../../../../shared/models/base/assoc-array';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {

  public postSearchHistory(search: string): Observable<{ record: SearchRecord, message: string }> {
    const endpoint = this.buildUrl(['me', 'history', 'search']);

    return this.post(endpoint, { search: search });
  }

  public getSearchHistory(params?: AssocArray): Observable<{ history: SearchRecord[], cnt_all: number }> {
    const endpoint = this.buildUrl(['me', 'history', 'search'], params);

    return this.get(endpoint);
  }

}
