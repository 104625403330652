import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { timer } from 'rxjs';

@Component({
  selector: 'shop-heatmap-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() points: [number, number, number][];

  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  constructor(
    private store: Store<any>,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.canvas.nativeElement.width = this.document.body.clientWidth;
    this.canvas.nativeElement.height = this.document.body.clientHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('points') && this.points) {
      timer(1)
        .subscribe(() => {
          simpleheat(this.canvas.nativeElement)
            .radius(5, 6)
            .gradient({
              '0.00': 'rgb(255,0,255)',
              '0.25': 'rgb(0,0,255)',
              '0.50': 'rgb(0,255,0)',
              '0.75': 'rgb(255,255,0)',
              '1.00': 'rgb(255,0,0)'
            })
            .data(this.points)
            .draw();
        });
    }
  }

}
