import * as SectionActions from '../actions/section.actions';

export interface State {
  activeSection: string;
}

export const initialState: State = {
  activeSection: ''
};

export function reducer(state = initialState, action: SectionActions.All) {
  switch (action.type) {
    case SectionActions.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.payload.section
      };
    default: {
      return state;
    }
  }
}
