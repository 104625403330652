import { Action } from '@ngrx/store';

export const SET_ACTIVE_SECTION = '[Section] SET active section';

export class SetActiveSection implements Action {
  readonly type = SET_ACTIVE_SECTION;

  constructor(public payload: { section: string }) { }
}

export type All =
  SetActiveSection;
