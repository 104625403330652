import { Action } from '@ngrx/store';

import { Product } from '../../../../../shared/models/product/product';

export const GET_PRODUCTS         = '[Comparator] GET products';
export const GET_PRODUCTS_SUCCESS = '[Comparator] GET products success';
export const GET_PRODUCTS_ERROR   = '[Comparator] GET products error';

export class GetProducts implements Action {
  readonly type = GET_PRODUCTS;

  constructor() { }
}

export class GetProductsSuccess implements Action {
  readonly type = GET_PRODUCTS_SUCCESS;

  constructor(public payload: { products: Product[] }) { }
}

export class GetProductsError implements Action {
  readonly type = GET_PRODUCTS_ERROR;

  constructor(public payload: { message: string }) { }
}

export type All
  = GetProducts
  | GetProductsSuccess
  | GetProductsError;
