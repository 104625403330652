import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { McRAIModal, McRAIModalOptions } from 'ui';
import { PostPassword } from '../../../../shop/modules/user-section/post-password/actions/post-password.actions';
import { LOGIN_MODAL_HEIGHT, LOGIN_MODAL_WIDTH, LoginComponent } from '../login/login.component';

@Component({
  selector: 'shop-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {

  readonly ERRORS = {
    username: {
      required: 'Zadejte název účtu, kde chcete vygenerovat nové heslo.',
    },
  };

  form: FormGroup = new FormGroup({
    username: new FormControl(null, Validators.required),
  });

  constructor(
    private modalService: McRAIModal,
    private store: Store<any>
  ) { }

  ngOnInit(): void { }

  public resetPassword(): void {
    this.store.dispatch(new PostPassword({
      body: {
        username: this.form.controls.username.value,
      },
      resetPasswordModalService: this.modalService,
    }));
  }

  public closeModal(): void {
    this.modalService.closeAll();
    this.modalService.open(LoginComponent, new McRAIModalOptions(LOGIN_MODAL_WIDTH, LOGIN_MODAL_HEIGHT, { }));
  }

  public enterKeyDown(event) {
    if (event.key === 'Enter') {
      this.resetPassword();
    }
  }
}
