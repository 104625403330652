import { Injectable } from '@angular/core';
import { CookieService } from 'ng2-cookies';
import { SectionService } from './section.service';
import { Token } from '../models/token';
import { Dictionary } from '@ngrx/entity';

@Injectable({
  providedIn: 'root'
})
export class BrowserTokenService {
  emptyToken: Token = { id: null, expiration: null };

  constructor(
    private sectionService: SectionService,
    private cookieService: CookieService,
  ) { }

  public get(section?: string|string[]): Dictionary<Token> {
    if (!Array.isArray(section)) {
      section = [section];
    }

    const ret = { };

    for (const s of section) {
      const token_key = this.getTokenKey(s);

      ret[s] = this.cookieService.check(token_key) ? JSON.parse(this.cookieService.get(token_key)) : this.emptyToken;
    }

    return ret;
  }

  public getOne(section?: string): Token {
    return this.get(section)[section];
  }

  public set(token: Token, section?: string|string[]): void {
    if (Array.isArray(section)) {
      for (const s of section) {
        this.set(token, s);
      }
    } else {
      const token_key = this.getTokenKey(section);

      this.cookieService.set(token_key, JSON.stringify(token), new Date(token.expiration), '/');
    }
  }

  public delete(section?: string|string[]): void {
    if (Array.isArray(section)) {
      for (const s of section) {
        this.delete(s);
      }
    } else {
      const token_key = this.getTokenKey(section);

      this.cookieService.delete(token_key, '/');
    }
  }

  private getTokenKey(section?: string): string {
    if (!section) {
      section = this.sectionService.getSection();
    }

    return section === this.sectionService.ADMIN ? 'admintoken' : 'token';
  }

  public isTokenExpired(token: Token): boolean {
    const currentDate = new Date();
    const expirationDate = new Date(token.expiration);

    return expirationDate <= currentDate;
  }
}
