import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToString'
})
export class EnumToStringPipe implements PipeTransform {

  transform(value: string|number, args: any): string {
    return value !== null && args ? args[value].replace(/([A-Z])/g, ' $1').toLowerCase() : '-- neznámá hodnota --';
  }

}
