import * as LoaderActions from '../actions/loader.actions';

export interface State {
  count: number;
}

export const initialState: State = {
  count: 0
};

export const featureName = 'sharedLoader';

export function reducer(state = initialState, action: LoaderActions.All): State {
  switch (action.type) {
    case LoaderActions.SHOW_LOADER:
      return {
        ...state,
        count: state.count + 1
      };
    case LoaderActions.HIDE_LOADER:
      return {
        ...state,
        count: state.count - 1
      };
    case LoaderActions.RESET_LOADER:
      return {
        ...state,
        count: 0
      };
    default:
      return state;
  }
}

export const selectLoaderCount = state => state[featureName].count;
