import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SectionService {
  readonly ADMIN = 'admin';
  readonly SHOP  = 'shop';
  readonly SECTIONS = [
    this.ADMIN, this.SHOP,
  ];
  readonly ALL = this.SECTIONS;

  buffered_section: string;

  constructor(
    private router: Router
  ) { }

  public getSection(event?: NavigationStart, buffer?: boolean): string {
    if (!event && !buffer && this.buffered_section) {
      return this.buffered_section;
    }

    const urlSource: NavigationStart|Router = event ? event : this.router;
    const section = urlSource.url.search('aegisx') !== -1 ? this.ADMIN : this.SHOP;

    if (buffer) {
      this.buffered_section = section;
    }

    return section;
  }
}
