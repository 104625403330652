import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { Injectable, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { UserModule } from './shared/user/user.module';
import { EffectsModule } from '@ngrx/effects';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { CookieService } from 'ng2-cookies';

import { AppComponent } from './app.component';

import { API_HTPASS, API_URL, DOMAIN, DOMAIN_SSR, IPP, ROOT_URL, ROOT_URL_SSR, TOKEN, WINDOW } from './app.config';
import { SectionModule } from './shared/section/section.module';
import { AdminPanelModule } from './shop/modules/admin-panel/admin-panel/admin-panel.module';
import { PostClickModule } from './shop/modules/heatmap/post-click/post-click.module';
import { Token } from './shared/user/models/token';
import { LOGOUT } from './shared/user/actions/logout.actions';
import { Observable } from 'rxjs';
import { McRAIToasterModule } from 'ui';
import { BrowserTokenService } from './shared/user/services/browser-token.service';
import { UtilsModule } from './shared/utils/utils.module';
import { BackToTopModule } from './shared/back-to-top/back-to-top.module';
import { GetRedirectModule } from './shop/modules/redirect/get-redirect/get-redirect.module';
import { RecaptchaModule } from 'ng-recaptcha';

@Injectable()
export class HammerConfig extends HammerGestureConfig { }

export class TokenProvider {
  emptyToken: Token = { id: null, expiration: null };

  constructor(
    private injector: Injector,
    private platformId: Object,
    // private cookieService: CookieService,
    private browserTokenService: BrowserTokenService,
  ) { }


  get id(): string {
    return this.getToken().id;
  }

  get expiration(): string {
    return this.getToken().expiration;
  }

  private getToken(): Token {
    switch (true) {
      case isPlatformBrowser(this.platformId):
        return this.getTokenBrowser();
      case isPlatformServer(this.platformId):
        return this.getTokenServer();
      default:
        return this.emptyToken;
    }
  }

  private getTokenServer(): Token {
    const request = this.injector.get<any>(REQUEST);

    return request.cookies && request.cookies.token ? JSON.parse(request.cookies.token) : this.emptyToken;
  }

  private getTokenBrowser(): Token {
    return this.browserTokenService.getOne();
  }

}

const WINDOW_PROVIDER = {
  provide: WINDOW,
  useFactory: (platformId) => {
    return isPlatformBrowser(platformId) ? window : {
      scrollTo(options?: ScrollToOptions): void { }
    };
  },
  deps: [PLATFORM_ID]
};

// @Injectable()
// export class CustomInterceptor implements HttpInterceptor {
//   constructor() {}
//
//   intercept(request: HttpRequest<any>, next: HttpHandler):
//     Observable<HttpEvent<any>> {
//     if (request.method === 'GET') {
//       console.log(request.method);
//       const customRequest = request.clone({
//         headers: request.headers
//           .set('Cache-Control', 'no-cache')
//           .set('Pragma', 'no-cache')
//       });
//       return next.handle(customRequest);
//     }
//
//     return next.handle(request);
//   }
// }

export function logout(reducer) {
  return function (state, action) {
    if (action.type === LOGOUT) {
      if (state.sharedSection && state.sharedSection.activeSection) {
        state = {
          sharedSection: {
            activeSection: state.sharedSection.activeSection
          }
        };
      } else {
        state = undefined;
      }
    }

    return reducer(state, action);
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SectionModule,
    UserModule,
    StoreModule.forRoot({ }, { metaReducers: [logout] }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    AdminPanelModule,
    BackToTopModule,
    PostClickModule,
    McRAIToasterModule,
    UtilsModule,
    GetRedirectModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: 'API_URL', useValue: API_URL },
    { provide: 'API_HTPASS', useValue: API_HTPASS },
    { provide: 'ROOT_URL', useValue: ROOT_URL },
    { provide: 'ROOT_URL_SSR', useValue: ROOT_URL_SSR },
    { provide: 'DOMAIN', useValue: DOMAIN },
    { provide: 'DOMAIN_SSR', useValue: DOMAIN_SSR },
    { provide: 'IPP', useValue: IPP },
    // TOKEN_PROVIDER,
    WINDOW_PROVIDER,
    CookieService,
    { provide: TOKEN, useClass: TokenProvider, deps: [Injector, PLATFORM_ID, BrowserTokenService] },
    // { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true, },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
