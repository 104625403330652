import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { selectAuthenticated } from '../reducers/user.reducer';
import { User } from '../models/user';
import { BrowserTokenService } from './browser-token.service';

@Injectable({
  providedIn: 'root'
})
export class UserChangeService implements OnInit, OnDestroy {
  destroy: Subject<any> = new Subject();

  user: User;
  tokenId: string;

  callbacks = { };

  constructor(
    private store: Store<any>,
    private browserTokenService: BrowserTokenService,
    // private cookieService: CookieService,
  ) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectAuthenticated),
        takeUntil(this.destroy),
        filter((user: User) => !!user)
      )
      .subscribe((user: User) => {
        if (!this.user) {
          this.user = user;
          this.tokenId = this.browserTokenService.getOne().id;
        } else {
          if (this.user.id !== user.id) {
            this.user = user;
            const intervalId = setInterval(() => {
              if (this.browserTokenService.getOne().id !== this.tokenId) {

                for (const key of Object.keys(this.callbacks)) {
                  this.callbacks[key]();
                }

                clearInterval(intervalId);
              }
            }, 1);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public addCallback(key: string, callback: () => void): void {
    this.callbacks[key] = callback;
  }

  public removeCallback(key: string): void {
    delete this.callbacks[key];
  }
}
