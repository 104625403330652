import { Injectable } from '@angular/core';
import { AssocArray } from '../../models/base/assoc-array';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  readonly KEY_TAGMAN = 'UA-44458034-1';
  readonly KEY_ADS    = 'AW-1000545420';

  private static checkGtag(): boolean {
    return typeof gtag !== 'undefined';
  }

  public standardEvent(
    eventName: string, eventCategory: string, eventAction: string,
    eventLabel: string = null, eventValue: number = null
  ): this {
    if (GtagService.checkGtag()) {
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue
      });
    }

    return this;
  }

  public customEvent(eventName: string, eventData: AssocArray): this {
    if (GtagService.checkGtag()) {
      gtag('event', eventName, eventData);
    }

    return this;
  }

  public customParams(...args): this {
    if (GtagService.checkGtag()) {
      gtag(...args);
    }

    return this;
  }
}
