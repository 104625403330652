import { Injectable } from '@angular/core';

@Injectable()
export class RandomGenerator {
  private _numbers: string[];
  private _letters: string[];
  private _lettersUC: string[];

  constructor() { }

  get numbers(): string[] {
    return this._numbers
      ? this._numbers
      : (this._numbers = Array.from(Array(10).keys()).map(String));
  }

  get letters(): string[] {
    return this._letters
      ? this._letters
      : (this._letters = Array.from(Array(26).keys()).map((code: number) => String.fromCharCode(97 + code)));
  }

  get lettersUpperCase(): string[] {
    return this._lettersUC
      ? this._lettersUC
      : (this._lettersUC = this.letters.map((letter: string) => letter.toUpperCase()));
  }

  public generate(length: number, symbols: string[]): string {
    let ret: string = '';

    for (let i = 0; i < length; i++) {
      ret += symbols[Math.floor(Math.random() * symbols.length)];
    }

    return ret;
  }

  public generateNumber(length): string {
    return this.generate(length, this.numbers);
  }

  public generateWord(length): string {
    return this.generate(length, this.letters);
  }

  public generateWordUC(length): string {
    return this.generate(length, this.lettersUpperCase);
  }

  public generateWordCS(length): string {
    return this.generate(length, this.letters.concat(this.lettersUpperCase));
  }

  public generateAlNum(length): string {
    return this.generate(length, this.numbers.concat(this.letters));
  }

  public generateAlNumUC(length): string {
    return this.generate(length, this.numbers.concat(this.lettersUpperCase));
  }

  public generateAlNumCS(length): string {
    return this.generate(length, this.numbers.concat(this.letters, this.lettersUpperCase));
  }

}
