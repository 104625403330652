import { Action } from '@ngrx/store';

import { Wishlist } from '../../../../../shared/models/wishlist/wishlist';

export const GET_WISHLISTS         = '[Wishlist] GET wishlists ';
export const GET_WISHLISTS_SUCCESS = '[Wishlist] GET wishlists success';
export const GET_WISHLISTS_ERROR   = '[Wishlist] GET wishlists error';

export const SELECT_ACTIVE_WIHLIST = '[Wishlist] SELECT active wishlist';

export class GetWishlists implements Action {
  readonly type = GET_WISHLISTS;

  constructor() { }
}

export class GetWishlistsSuccess implements Action {
  readonly type = GET_WISHLISTS_SUCCESS;

  constructor(public payload: { items: Wishlist[] }) { }
}

export class GetWishlistsError implements Action {
  readonly type = GET_WISHLISTS_ERROR;

  constructor(public payload: { message: string }) { }
}

export class SelectActiveWishlist implements Action {
  readonly type = SELECT_ACTIVE_WIHLIST;

  constructor(public payload: { wishlist: Wishlist }) { }
}

export type All
 = GetWishlists
 | GetWishlistsSuccess
 | GetWishlistsError
 | SelectActiveWishlist;
