import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { McRAILoader2 } from 'ui';
import { McRAIToaster } from 'ui';
import { Router } from '@angular/router';

import { DataService } from '../services/data.service';

@Injectable()
export class BaseRedirectEffects {
  constructor(
    protected actions: Actions,
    protected redirectService: DataService,
    protected store: Store<any>,
    protected router: Router,
    protected loaderService: McRAILoader2,
    protected toasterService: McRAIToaster
  ) { }
}
