import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ErrorEffects } from './effects/error.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      ErrorEffects
    ])
  ],
  declarations: []
})
export class ErrorModule { }
