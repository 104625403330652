import { Inject, Injectable } from '@angular/core';
import { Token } from '../../user/models/token';
import { TOKEN } from '../../../app.config';

@Injectable()
export class UrlProcessing {
  constructor(
    @Inject('API_URL') protected apiUrl: string,
    @Inject(TOKEN) protected token: Token,
  ) { }

  public buildUrl(
    path: string | string[], get: Object = { }, useIPP: boolean = false,
    ignoreEmptyParams: boolean = true, ignoreZeroParams: boolean = true
  ): string {
    const defaultParams = {
      token: this.token.id
    };

    return this.apiUrl
      + this.buildPath(path)
      + this.processGetParams(Object.assign(defaultParams, get), ignoreEmptyParams, ignoreZeroParams);
  }

  // noinspection JSMethodCanBeStatic
  public buildPath(path: string | string[]): string {
    return typeof path === 'string' ? path : path.join('/');
  }

  protected processGetParams(get: Object, ignoreEmpty: boolean = true, ignoreZero: boolean = true): string {
    const parts = [];

    Object.keys(get).forEach((prop) => {
      const val = get[prop];
      if (val instanceof Array) {
        if (val.length) {
          const string = val.reduce((sum, value) => {
            sum.push(`${prop}[]=` + encodeURIComponent(value));
            return sum;
          }, []);
          parts.push(string.join('&'));
        }
      } else if (val || !ignoreEmpty || (val === 0 && !ignoreZero)) {
        parts.push(`${prop}=` + encodeURIComponent(get[prop]));
      }
    });

    return parts.length ? '?' + parts.join('&') : '';
  }

}
