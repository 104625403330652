import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RootComponent } from './components/root/root.component';
import { McRAIButtonModule } from 'ui';
import { GetClicksModule } from '../../heatmap/get-clicks/get-clicks.module';

@NgModule({
  imports: [
    CommonModule,
    GetClicksModule,
    McRAIButtonModule
  ],
  declarations: [
    RootComponent
  ],
  exports: [
    RootComponent
  ]
})
export class AdminPanelModule { }
