import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepperSelectionEvent } from '@angular/cdk/stepper/typings/stepper';
import { McRAIModal, McRAIHorizontalTabs, MCRAI_MODAL_DATA } from 'ui';

import { Registration } from '../../actions/user.actions';

import * as UserActions from '../../actions/user.actions';
import { MAIL_GREG, PHONE_GREG } from '../../../base/gregs';

export const LOGIN_MODAL_WIDTH  = '450px';
export const LOGIN_MODAL_HEIGHT = '400px';
// export const REG_MODAL_WIDTH  = '450px';
// export const REG_MODAL_HEIGHT = '400px';

@Component({
  selector: 'shared-login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy/*, AfterViewInit*/ {
  destroy: Subject<null> = new Subject<null>();

  @ViewChild('tabs', { static: true }) private tabs: McRAIHorizontalTabs;

  loginForm: FormGroup = new FormGroup({
    username: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required)
  });

  registrationForm: FormGroup = new FormGroup({
    username:       new FormControl(null, [
      Validators.required, Validators.pattern(MAIL_GREG)
    ]),
    password:       new FormControl(null, [
      Validators.required, Validators.minLength(8)
    ]),
    password_again: new FormControl(null, [
      Validators.required// , Validators.minLength(8)
    ]),
    nickname:       new FormControl(),
    fullname:       new FormControl(null, Validators.required),
    phone:          new FormControl(null, Validators.pattern(PHONE_GREG)),
    street:         new FormControl(),
    city:           new FormControl(),
    postal_code:    new FormControl(),
    tin:            new FormControl(),
    vat:            new FormControl(),
  }, LoginComponent.validateRegistrationForm.bind(this));

  public readonly loginErrors: ValidationErrors = {
    username: 'Vaše uživatelské jméno.',
    password: 'Vaše heslo.',
  };

  public readonly registrationErrors: ValidationErrors = {
    username: {
      required: 'Zadejte login pro přihlášení k účtu',
      pattern: 'Login musí být e-mail',
    },
    password: {
      required: 'Zadejte heslo pro přihlášení k účtu',
      minlength: 'Heslo musí být minimálně 8 znaků dlouhé',
    },
    password_again: {
      required: 'Zopakujte heslo',
      match: 'Zadaná hesla se neshodují',
    },
    fullname: 'Vaše jméno a příjmení',
    email: {
      email: 'Zadejte e-mail se správném formátu: xxx@xxx nebo xxx@xxx.xx',
      required: 'Zadejte svojí e-mailovou adresu',
    },
    phone: {
      pattern: 'Telefon není ve formátu (+420) 123 456 789',
    }
  };

  private static validateRegistrationForm(form: FormGroup): null {
    if (
      form.controls.password_again.value
      && form.controls.password.value !== form.controls.password_again.value
    ) {
      form.controls.password_again.setErrors({ match: true });
    }

    return null;
  }

  constructor(
    private store: Store<any>,
    private modalService: McRAIModal,
    @Inject(MCRAI_MODAL_DATA) public data: {
      unclosable?: boolean,
    }
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  // ngAfterViewInit() {
  //   this.tabs.selectionChange
  //     .pipe(
  //       takeUntil(this.destroy)
  //     )
  //     .subscribe((value: StepperSelectionEvent) => {
  //       switch (value.selectedIndex) {
  //         case 0:
  //           this.modalService.resize(LOGIN_MODAL_WIDTH, LOGIN_MODAL_HEIGHT);
  //           break;
  //         case 1:
  //           this.modalService.resize(REG_MODAL_WIDTH, REG_MODAL_HEIGHT);
  //           break;
  //       }
  //     });
  // }

  public close(): void {
    this.modalService.close();
  }

  public login(): boolean {
    this.store.dispatch(new UserActions.Login({
      credentials: this.loginForm.value,
      loginModalService: this.modalService,
    }));

    return false;
  }

  public register(): boolean {
    this.store.dispatch(new Registration({
      body: {
        ...this.registrationForm.value,
        nickname: this.registrationForm.value.fullname
      },
      loginModalService: this.modalService,
    }));

    return false;
  }
}
