import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ToastType } from 'ui';

import { BaseUserSectionEffects } from '../../base-user-section/effects/base-user-section.effects';

import * as ModuleActions from '../actions/post-password.actions';

@Injectable()
export class PostPasswordEffects extends BaseUserSectionEffects {

  @Effect()
  postPassword: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.POST_PASSWORD),
      tap(() => this.loaderService.show()),
      switchMap((action: ModuleActions.PostPassword) => {
        return this.dataService
          .postPassword(action.payload.body)
          .pipe(
            map((result: any) => new ModuleActions.PostPasswordSuccess(
              result, action.payload.resetPasswordModalService
            )),
            catchError(result => of(new ModuleActions.PostPasswordError(result)))
          );
      })
    );

  @Effect({ dispatch: false })
  postPasswordSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.POST_PASSWORD_SUCCESS),
      tap((action: ModuleActions.PostPasswordSuccess) => {
        if (action.resetPasswordModalService) {
          action.resetPasswordModalService.close();
        }

        this.loaderService.hide();
        this.toasterService.makeToast(action.payload.message, ToastType.Success, 10000);
        this.modalService.close();
      })/*,
      switchMap((action: ModuleActions.PostPasswordSuccess) => {
        return of(new Logout());
      })*/
    );

  @Effect({ dispatch: false })
  postPasswordError: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.POST_PASSWORD_ERROR),
      tap((action: ModuleActions.PostPasswordError) => {
        this.loaderService.hide();
        this.toasterService.makeToast(action.payload.error.message, ToastType.Error);
      })
    );

}
