import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { McRAILoaderModule } from 'ui';
import { McRAIToasterModule } from 'ui';
import { McRAIInputModule } from 'ui';
import { McRAITabsModule } from 'ui';
import { McRAIModalModule } from 'ui';
import { McRAIPanelModule } from 'ui';
import { McRAIGridModule } from 'ui';
import { McRAIButtonModule } from 'ui';

import { UserService } from './services/user.service';
import { AuthorizeGuard } from './guards/authorize.guard';
import { AuthenticateGuard } from './guards/authenticate.guard';
import { LoginEffects } from './effects/login.effects';
import { AuthorizeEffects } from './effects/authorize.effects';
import { AuthenticateEffects } from './effects/authenticate.effects';
import { LogoutEffects } from './effects/logout.effects';
import { PermissionService } from './services/permission.service';
import { LoginComponent } from './components/login/login.component';
import { RegisterEffects } from './effects/register.effects';
import { ExportsEffects } from './effects/exports.effects';

import * as fromUser from './reducers/user.reducer';
import { ResetPasswordModalComponent } from './components/reset-password-modal/reset-password-modal.component';
import { ResetPasswordButtonComponent } from './components/reset-password-button/reset-password-button.component';
import { PostPasswordEffects } from '../../shop/modules/user-section/post-password/effects/post-password.effects';
import { UnconfirmedExportsModalComponent } from './components/unconfirmed-exports-modal/unconfirmed-exports-modal.component';
import { SharedModule } from '../shared/shared.module';
import { UserChangeService } from './services/user-change.service';
import { SectionService } from './services/section.service';
import { BrowserTokenService } from './services/browser-token.service';
import { GetSearchHistoryModule } from '../../shop/modules/history/get-search-history/get-search-history.module';

@NgModule({
  imports: [
    CommonModule,
    McRAILoaderModule,
    McRAIToasterModule,
    McRAIInputModule,
    McRAITabsModule,
    McRAIModalModule,
    McRAIPanelModule,
    McRAIGridModule,
    McRAIButtonModule,
    RouterModule,
    SharedModule,
    StoreModule.forFeature(fromUser.featureName, fromUser.reducer),
    EffectsModule.forFeature([
      AuthenticateEffects,
      AuthorizeEffects,
      LoginEffects,
      LogoutEffects,
      RegisterEffects,
      ExportsEffects,
      PostPasswordEffects,
    ]),
    GetSearchHistoryModule,
  ],
  declarations: [
    LoginComponent,
    ResetPasswordModalComponent,
    ResetPasswordButtonComponent,
    UnconfirmedExportsModalComponent,
  ],
  entryComponents: [
    LoginComponent,
    ResetPasswordModalComponent,
    ResetPasswordButtonComponent,
    UnconfirmedExportsModalComponent,
  ],
  providers: [
    UserService,
    AuthenticateGuard,
    AuthorizeGuard,
    PermissionService,
    UserChangeService,
    SectionService,
    BrowserTokenService,
  ]
})
export class UserModule { }
