/* tslint:disable:max-line-length */
export const NON_EMPTY_GREG = /\S/;
export const NOT_EMPTY_TEXT_GREG = /^.*[^\s].*$/;

export const MAIL_GREG = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i;

export const DATE_GREG = /^(\d){1,2}\.(\d){1,2}\.(\d){4}$/;

export const PERMISSION_ID_GREG = /^([a-z]*)([a-z]+[.]?[a-z+])+([a-z])*$/;

export const PHONE_GREG = /^\(?((\+(\(?\d)(\)?\s?[.-]?\s?\(?\d){11,})|((\(?\d)(\)?\s?[.-]?\s?\(?\d){8,}))\)?$/;

export const GPS_GREG = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const UINT_GREG   = /^\d+$/;
export const INT_GREG    = /^-?\d+$/;

export const UFLOAT_GREG = /^\d+(\.\d+)?$/;
export const FLOAT_GREG  = /^-?\d+(\.\d+)?$/;

export const UPRICE_GREG = /^\d+(\.\d{1,2})?$/;
export const PRICE_GREG  = /^-?\d+(\.\d{1,2})?$/;

export const RELATIVE_URL_PATH_GREG = /^\/[^\s?#]*$/;
export const RELATIVE_OR_ABSOLUTE_URL_PATH_GREG = /^(?!\/\/)(https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:[^\s?#]*(?:\?[^\s#]*)?(?:#[^\s]*)?)?|\/[^\s?#]+(?:\?[^\s#]*)?(?:#[^\s]*)?)$/;

export const FULLNAME_CHARS_GREG = /^[^.,:&@]*$/;
export const FULLNAME_MIN_2_PARTS_GREG = /\S+\s+\S+/;
