import { Action } from '@ngrx/store';

import { ShopMessage } from '../../../../../shared/models/messages/shop-message';
import { Paginator } from '../../../../../shared/models/shared/paginator';

export const GET_MESSAGES              = '[Messages] GET messages';
export const GET_MESSAGES_SUCCESS      = '[Messages] GET messages success';
export const GET_MESSAGES_ERROR        = '[Messages] GET messages error';
export const GET_MESSAGES_LIST         = '[Messages] GET messages list';
export const GET_MESSAGES_LIST_SUCCESS = '[Messages] GET messages list success';
export const GET_MESSAGES_LIST_ERROR   = '[Messages] GET messages list error';

export class GetMessages implements Action {
  readonly type = GET_MESSAGES;

  constructor(public payload: { id: string, page: number }) { }
}

export class GetMessagesSuccess implements Action {
  readonly type = GET_MESSAGES_SUCCESS;

  constructor(public payload: { id: string, messages: ShopMessage[] } ) { }
}

export class GetMessagesError implements Action {
  readonly type = GET_MESSAGES_ERROR;

  constructor(public payload: { message: string }) { }
}

export class GetMessagesList implements Action {
  readonly type = GET_MESSAGES_LIST;

  constructor(public payload: { id: string, page: number }) { }
}

export class GetMessagesListSuccess implements Action {
  readonly type = GET_MESSAGES_LIST_SUCCESS;

  constructor(public payload: { id: string, messages: ShopMessage[], paginator: Paginator } ) { }
}

export class GetMessagesListError implements Action {
  readonly type = GET_MESSAGES_LIST_ERROR;

  constructor(public payload: { message: string }) { }
}

export type All
  = GetMessages
  | GetMessagesSuccess
  | GetMessagesError
  | GetMessagesList
  | GetMessagesListSuccess
  | GetMessagesListError
;
