import { Injectable } from '@angular/core';

import { Product } from '../../models/product/product';
import { Article } from '../../models/article/article';
import { Category } from '../../models/category/category';
import {
  ARTICLE_ROUTE,
  CART_ROUTE,
  CATEGORY_ROUTE,
  COMPARATOR_ROUTE, CUSTOM_SET,
  PRODUCT_ROUTE, SET_ROUTE, SEARCH,
  SITEMAP_ROUTE, US_EXPORTS, US_HISTORY, US_MESSAGES, US_PROFILE, USER_SECTION, WISHLIST
} from '../../../app.config';
import { BaseService } from '../../base/base.service';
import { ShopMessage } from '../../models/messages/shop-message';
import { Set } from '../../models/sets/set';

@Injectable({
  providedIn: 'root'
})
export class LinkService extends BaseService {

  // noinspection JSMethodCanBeStatic
  private getLanguage() {
    return 'cs';
  }

  private buildLink(path: string | string[]) {
    return '/' + this.buildPath(path);
  }

  // noinspection JSMethodCanBeStatic
  private extractURL(item: Category | Product | Set | Article | string): string {
    if (!item) {
      return '';
    }

    return typeof item === 'string' ? item : item.info[0].url;
  }

  // noinspection JSMethodCanBeStatic
  private extractId(item: ShopMessage | string): string {
    if (!item) {
      return '';
    }

    return typeof item === 'string' ? item : item.id;
  }

  public getArticlesLink(): string {
    const linkParts = [
      this.getLanguage(),
      ARTICLE_ROUTE
    ];

    return this.buildLink(linkParts);
  }

  public getArticleLink(article: Article | string): string {
    const linkParts = [
      this.getArticlesLink(),
      this.extractURL(article)
    ];

    return this.buildLink(linkParts);
  }

  public getCategoriesLink(): string {
    const linkParts = [
      this.getLanguage(),
      CATEGORY_ROUTE
    ];

    return this.buildLink(linkParts);
  }

  public getCategoryLink(category: Category | string): string {
    const url: string = this.extractURL(category);
    if (url === 'homepage') {
      return this.getHomepageLink();
    }

    const linkParts = [
      this.getCategoriesLink(),
      url
    ];

    return this.buildLink(linkParts);
  }

  public getProductsLink(): string {
    const linkParts = [
      this.getLanguage(),
      PRODUCT_ROUTE
    ];

    return this.buildLink(linkParts);
  }

  public getProductLink(product: Product | string): string {
    const linkParts = [
      this.getProductsLink(),
      this.extractURL(product)
    ];

    return this.buildLink(linkParts);
  }

  public getSetsLink(): string {
    const linkParts = [
      this.getLanguage(),
      SET_ROUTE
    ];

    return this.buildLink(linkParts);
  }

  public getSetLink(set: Set | string): string {
    const linkParts = [
      this.getSetsLink(),
      this.extractURL(set)
    ];

    return this.buildLink(linkParts);
  }

  public getHomepageLink(): string {
    const linkParts = [
      this.getLanguage()
    ];

    return this.buildLink(linkParts);
  }

  public getComparatorLink(): string {
    const linkParts = [
      this.getLanguage(),
      COMPARATOR_ROUTE
    ];

    return this.buildLink(linkParts);
  }

  public getSitemapLink(): string {
    const linkParts = [
      this.getLanguage(),
      SITEMAP_ROUTE
    ];

    return this.buildLink(linkParts);
  }

  public getCartLink(): string {
    const parts = [this.getLanguage(), CART_ROUTE];

    return this.buildLink(parts);
  }

  public getSearchLink(): string {
    const parts = [this.getLanguage(), SEARCH];

    return this.buildLink(parts);
  }

  public getUserSectionLink(): string {
    const parts = [this.getLanguage(), USER_SECTION];

    return this.buildLink(parts);
  }

  public getUserProfileLink(): string {
    const linkParts = [
      this.getUserSectionLink(),
      US_PROFILE
    ];

    return this.buildLink(linkParts);
  }

  public getShopMessagesLink(): string {
    const linkParts = [
      this.getUserSectionLink(),
      US_MESSAGES
    ];

    return this.buildLink(linkParts);
  }

  public getShopMessageLink(message: ShopMessage | string): string {
    const linkParts = [
      this.getShopMessagesLink(),
      this.extractId(message)
    ];

    return this.buildLink(linkParts);
  }

  public getShopExportsLink(): string {
    const linkParts = [
      this.getUserSectionLink(),
      US_EXPORTS
    ];

    return this.buildLink(linkParts);
  }

  public getUserHistoryLink(): string {
    const linkParts = [
      this.getUserSectionLink(),
      US_HISTORY
    ];

    return this.buildLink(linkParts);
  }

  public getWishlistLink(): string {
    const parts = [this.getLanguage(), WISHLIST];

    return this.buildLink(parts);
  }

  public getRootUrl(): string {
    return this.rootUrlSsr ? this.rootUrlSsr.replace(/\/$/, '') : this.rootUrl.replace(/\/$/, '');
  }

  public getProductsAbsoluteUrl(): string {
    return this.getRootUrl() + this.getProductsLink() + '/';
  }

  public getCategoriesAbsoluteUrl(): string {
    return this.getRootUrl() + this.getCategoriesLink() + '/';
  }

  public getArticlesAbsoluteUrl(): string {
    return this.getRootUrl() + this.getArticlesLink() + '/';
  }

  public getCartAbsoluteUrl(): string {
    return this.getRootUrl() + this.getCartLink() + '/';
  }

  public getShopExportsAbsoluteUrl(): string {
    return this.getRootUrl() + this.getShopExportsLink() + '/';
  }

  public getUserHistoryAbsoluteUrl(): string {
    return this.getRootUrl() + this.getUserHistoryLink() + '/';
  }

  // noinspection JSMethodCanBeStatic
  public getDistributionNetworkLink(): string {
    return 'https://corporate.helvetia.cz/cs/k/distribucni-sit';
  }

  public getCustomSetLink(): string {
    const linkParts = [
      this.getLanguage(),
      CUSTOM_SET
    ];

    return this.buildLink(linkParts);
  }

}
