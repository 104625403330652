import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import * as UserActions from '../actions/user.actions';

@Injectable()
export class AuthorizeGuard implements CanActivate {

  constructor(
    private store: Store<any>,
    private actions: Actions
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(new UserActions.Authorize({ url: state.url }));

    return this.actions
      .pipe(
        ofType(UserActions.AUTHORIZE_COMPLETE),
        first(),
        switchMap((action: UserActions.AuthorizeComplete) => of(action.payload.allow))
      );
  }
}
