import { Injectable } from '@angular/core';
import { ObjectFilter } from './object-filter';

@Injectable()
export class ObjectProcessing {
  constructor() { }

  // noinspection JSMethodCanBeStatic
  private deleteKey(obj: any, prop: string, set_to_null: boolean): void {
    if (set_to_null) {
      obj[prop] = null;
    } else {
      delete obj[prop];
    }
  }

  public deleteEmptyKeys(
    obj: Object, filter: ObjectFilter = { }
  ): Object {
    const ret: Object = Object.assign({ }, obj);

    Object.keys(ret).forEach((prop: string) => {
      const val = ret[prop];

      if (!filter.let_empty && (val === null || val === undefined)) {
        this.deleteKey(ret, prop, filter.set_to_null);
        return;
      }

      if (!filter.let_false && val === false) {
        this.deleteKey(ret, prop, filter.set_to_null);
        return;
      }

      if (!filter.let_zero && val === 0) {
        this.deleteKey(ret, prop, filter.set_to_null);
        return;
      }

      if (!filter.let_nan && Number.isNaN(val)) {
        this.deleteKey(ret, prop, filter.set_to_null);
        return;
      }

      if (!filter.let_empty_str && val === '') {
        this.deleteKey(ret, prop, filter.set_to_null);
        return;
      }

      if (!filter.let_empty_array && Array.isArray(val) && val.length === 0) {
        this.deleteKey(ret, prop, filter.set_to_null);
        return;
      }

      if (!filter.no_recursion && typeof val === 'object' && !Array.isArray(val)) {
        const tmp = this.deleteEmptyKeys(val, filter);
        if (Object.keys(tmp).length > 0) {
          ret[prop] = tmp;
        } else {
          this.deleteKey(ret, prop, filter.set_to_null);
        }
      }
    });

    return ret;
  }

}
