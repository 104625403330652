import { Injectable } from '@angular/core';
import { Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseHeatmapEffects } from '../../base-heatmap/effects/base-heatmap.effects';
import { GetClicksSuccess } from '../../get-clicks/actions/get-clicks.actions';

import * as ModuleActions from '../actions/post-click.actions';

@Injectable()
export class PostClickEffects extends BaseHeatmapEffects {

  @Effect()
  postClick: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.POST_CLICK),
      switchMap((action: ModuleActions.PostClick) => {
        return this.dataService
          .postClick(action.payload.page, action.payload.point)
          .pipe(
            map(() => new ModuleActions.PostClickSuccess())
          );
      })
    );

/*  @Effect()
  postClickSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.POST_CLICK_SUCCESS),
      map((action: ModuleActions.PostClickSuccess) => new GetClicksSuccess(action.payload))
    );*/

}
