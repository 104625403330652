import { Action } from '@ngrx/store';
import { ActionDisplayEnum } from '../../models/shared/action-display-enum';
import { ErrorGui } from '../../models/errors/error-gui';

export const GET_HAS_UNCONFIRMED_EXPORTS         = '[Cart] GET has unconfirmed exports';
export const GET_HAS_UNCONFIRMED_EXPORTS_SUCCESS = '[Cart] GET has unconfirmed exports success';
export const GET_HAS_UNCONFIRMED_EXPORTS_ERROR   = '[Cart] GET has unconfirmed exports error';

export class GetHasUnconfirmedExports implements Action {
  readonly type = GET_HAS_UNCONFIRMED_EXPORTS;

  constructor(public displayType: ActionDisplayEnum = ActionDisplayEnum.Message) { }
}

export class GetHasUnconfirmedExportsSuccess implements Action {
  readonly type = GET_HAS_UNCONFIRMED_EXPORTS_SUCCESS;

  constructor(
    public payload: { has_unconfirmed: boolean },
    public displayType: ActionDisplayEnum
  ) { }
}

export class GetHasUnconfirmedExportsError implements Action {
  readonly type = GET_HAS_UNCONFIRMED_EXPORTS_ERROR;

  constructor(public payload: ErrorGui) { }
}
