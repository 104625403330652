import { Action } from '@ngrx/store';

import { Credentials } from '../models/credentials';
import { Token } from '../models/token';
import { McRAIModal } from 'ui';
import { ErrorGui } from '../../models/errors/error-gui';
import { UserResponse } from '../models/user-response';

export const LOGIN               = '[User] Login';
export const LOGIN_SUCCESS       = '[User] Login Success';
export const LOGIN_ERROR         = '[User] Login Error';
export const LOGIN_REDIRECT      = '[User] Login Redirect';

export const ADMIN_LOGIN         = '[User] Admin Login';
export const ADMIN_LOGIN_SUCCESS = '[User] Admin Login Success';
export const ADMIN_LOGIN_ERROR   = '[User] Admin Login Error';

export const SAVE_TOKEN   = '[User] Save Token';
export const REMOVE_TOKEN = '[User] Remove Token';

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: { credentials: Credentials, loginModalService?: McRAIModal }) { }
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(
    public payload: UserResponse,
    public loginModalService?: McRAIModal
  ) { }
}

export class LoginError implements Action {
  readonly type = LOGIN_ERROR;

  constructor(public payload: ErrorGui) { }
}

export class LoginRedirect implements Action {
  readonly type = LOGIN_REDIRECT;

  constructor(public payload: { url: string, new_window?: boolean, }) { }
}


export class AdminLogin implements Action {
  readonly type = ADMIN_LOGIN;

  constructor(public payload: { user_id: string, }) { }
}
export class AdminLoginSuccess implements Action {
  readonly type = ADMIN_LOGIN_SUCCESS;

  constructor(public payload: UserResponse) { }
}

export class AdminLoginError implements Action {
  readonly type = ADMIN_LOGIN_ERROR;

  constructor(public payload: ErrorGui) { }
}


export class SaveToken implements Action {
  readonly type = SAVE_TOKEN;

  constructor(public payload: { token: Token, section?: string|string[] }) { }
}

export class RemoveToken implements Action {
  readonly type = REMOVE_TOKEN;

  constructor(public payload: { section?: string|string[] }) { }
}
