import { Type } from '../models/type';

import * as MessageActions from '../actions/message.actions';

export interface State {
  text: string;
  type: Type;
  show: boolean;
}

export const initialState: State = {
  text: '',
  type: null,
  show: false,
};

export function reducer(state = initialState, action: MessageActions.All) {
  switch (action.type) {
    case MessageActions.SHOW_MESSAGE:
      return action.payload;
    default:
      return state;
  }
}
