import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { McRAILoader2 } from 'ui';
import { McRAIToaster } from 'ui';
import { McRAIModal } from 'ui';

import { LinkService } from '../../link/services/link.service';
import { ActionDisplayEnum } from '../../models/shared/action-display-enum';
import { ModalOptions } from 'ui';
import { ToastType } from 'ui/src/toaster/models/toast-type.enum';
import { GetHasUnconfirmedExportsSuccess } from '../actions/exports.actions';
import { ComponentType } from '@angular/cdk/portal';
import { BrowserTokenService } from '../services/browser-token.service';
import { SectionService } from '../services/section.service';
import { WINDOW } from '../../../app.config';

@Injectable()
export class UserEffects {
  constructor(
    protected actions: Actions,
    protected userService: UserService,
    protected router: Router,
    protected store: Store<any>,
    protected loaderService: McRAILoader2,
    protected toasterService: McRAIToaster,
    protected modalService: McRAIModal,
    // protected cookieService: CookieService,
    protected linkService: LinkService,
    protected sectionService: SectionService,
    protected browserTokenService: BrowserTokenService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    @Inject(WINDOW) protected window: Window,
  ) { }

  protected processDisplayAction<T>(
    action: GetHasUnconfirmedExportsSuccess,
    text: string, header: string, modalType: ComponentType<T>
  ): void {
    switch (action.displayType) {
      case ActionDisplayEnum.None:
        break;
      case ActionDisplayEnum.Modal:
        const options = new ModalOptions('500px', '250px', {
          text: text, header: header
        }, false);
        this.modalService.open(modalType, options);
        break;
      case ActionDisplayEnum.Message:
        this.toasterService.makeToast(
          text, ToastType.Error, 8000 // this.toasterService.INFINITE_DURATION
        );
    }
  }
}
