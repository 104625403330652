import { throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Token } from '../user/models/token';
import { TOKEN } from '../../app.config';
import { ObjectProcessing } from '../utils/models/object-processing';
import { UrlProcessing } from '../utils/models/url-processing';

@Injectable()
export abstract class BaseService {
  constructor(
    protected http: HttpClient,
    protected objProc: ObjectProcessing,
    protected urlProc: UrlProcessing,
    @Inject('API_URL') protected apiUrl: string,
    @Inject('API_HTPASS') protected apiPass: string,
    @Inject('ROOT_URL') protected rootUrl: string,
    @Inject('ROOT_URL_SSR') protected rootUrlSsr: string,
    @Inject(TOKEN) protected token: Token,
  ) { }

  protected buildUrl(
    path: string | string[], get: Object = { }, useIPP: boolean = false,
    ignoreEmptyParams: boolean = true, ignoreZeroParams: boolean = true
  ): string {
    return this.urlProc.buildUrl(path, get, useIPP, ignoreEmptyParams, ignoreZeroParams);
  }

  // noinspection JSMethodCanBeStatic
  protected buildPath(path: string | string[]): string {
    return this.urlProc.buildPath(path);
  }

  // noinspection JSMethodCanBeStatic
  private prepareHeaders(htpasswd?: string, nocache: boolean = false): HttpHeaders {
    let headers = new HttpHeaders();

    if (htpasswd) {
      headers = headers.set('Authorization', 'Basic ' + btoa(htpasswd));
    }

    return headers;
  }

  private prepareXhrOptions(nocache: boolean = false): object {
    return {
      headers: this.prepareHeaders(this.apiPass, nocache),
    };
  }

  get<T>(url: string) {
    const options = this.prepareXhrOptions(true);

    return this.http
      .get<T>(url, options)
      .pipe(
        catchError(res => this.handleError(res, url))
      );
  }

  post<T>(url: string, body: any | null) {
    const options = this.prepareXhrOptions();

    return this.http
      .post<T>(url, body, options)
      .pipe(
        catchError(res => this.handleError(res, url))
      );
  }

  patch<T>(url: string, body: any) {
    const options = this.prepareXhrOptions();

    return this.http
      .patch<T>(url, body, options)
      .pipe(
        catchError(res => this.handleError(res, url))
      );
  }

  del<T>(url: string) {
    const options = this.prepareXhrOptions();

    return this.http
      .delete<T>(url, options)
      .pipe(
        catchError(res => this.handleError(res, url))
      );
  }

  // noinspection JSMethodCanBeStatic
  handleError(response: HttpErrorResponse, url: string) {
    console.log('Error for URL:', url, response);

    return throwError({
      message: response.statusText, status: response.status, error: response.error
    });
  }

  // noinspection JSMethodCanBeStatic
  extractIds(items: any[]): string[] {
    return items.reduce((all_ids, item) => all_ids.concat(item.id), []);
  }

}
