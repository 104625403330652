import { Directive, ElementRef, Input, OnChanges, Sanitizer, SecurityContext, SimpleChanges } from '@angular/core';

@Directive({ selector: '[safeHtml], [safeHTML]' })
export class SafeHtmlDirective implements OnChanges {
  private _html: string;

  @Input() set safeHtml(value: string) {
    this._html = value;
  }

  @Input() set safeHTML(value: string) {
    this._html = value;
  }

  constructor(
    private _elRef: ElementRef,
    private _sanitizer: Sanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges): any {
    this._elRef.nativeElement.innerHTML = this._sanitizer
      .sanitize(SecurityContext.NONE, this._html); // UNSAFE thing
  }
}
