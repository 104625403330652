import { Action } from '@ngrx/store';

import { Cart } from '../../../../../shared/models/cart/cart';
import { ShopOrder } from '../../../../../shared/models/order/shop-order';
import { ErrorGui } from '../../../../../shared/models/errors/error-gui';

export const GET_CARTS         = '[Carts] GET carts';
export const GET_CARTS_SUCCESS = '[Carts] GET carts success';
export const GET_CARTS_ERROR   = '[Carts] GET carts error';

export const SELECT_ACTIVE_CART         = '[Carts] SELECT active cart';
export const SELECT_ACTIVE_CART_SUCCESS = '[Carts] SELECT active cart success';
export const SELECT_ACTIVE_CART_ERROR   = '[Carts] SELECT active cart error';

export class GetCarts implements Action {
  readonly type = GET_CARTS;

  constructor() { }
}

export class GetCartsSuccess implements Action {
  readonly type = GET_CARTS_SUCCESS;

  constructor(public payload: {
    cart: Cart, carts: Cart[], active_cart?: string,
    order?: ShopOrder, displayError?: boolean, errorMessage?: string
  }) { }
}

export class GetCartsError implements Action {
  readonly type = GET_CARTS_ERROR;

  constructor(public payload: { message: string }) { }
}

export class SelectActiveCart implements Action {
  readonly type = SELECT_ACTIVE_CART;

  constructor(public payload: { name: string }) { }
}

export class SelectActiveCartSuccess implements Action {
  readonly type = SELECT_ACTIVE_CART_SUCCESS;

  constructor(public payload: { message: string, active_cart: string }) { }
}

export class SelectActiveCartError implements Action {
  readonly type = SELECT_ACTIVE_CART_ERROR;

  constructor(public payload: ErrorGui) { }
}

export type All
  = GetCarts
  | GetCartsSuccess
  | GetCartsError
  | SelectActiveCart
  | SelectActiveCartSuccess
  | SelectActiveCartError;
