import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { DataService } from '../services/data.service';
import { McRAIModal, McRAIToaster } from 'ui';
import { McRAILoader2 } from 'ui';

@Injectable()
export class BaseHistoryEffects {
  constructor(
    protected dataService: DataService,
    protected actions: Actions,
    protected store: Store<any>,
    protected router: Router,
    protected toastService: McRAIToaster,
    protected loaderService: McRAILoader2,
    protected modalService: McRAIModal
  ) { }
}
