import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PermissionService } from './shared/user/services/permission.service';

import * as SectionActions from './shared/section/actions/section.actions';
import * as HeatmapActions from './shop/modules/heatmap/post-click/actions/post-click.actions';
import * as RedirectActions from './shop/modules/redirect/get-redirect/actions/get-redirect.actions';
import { DOCUMENT } from '@angular/common';
import { BROWSER_NOT_SUPPORTED, WINDOW } from './app.config';
import { McRAIToaster } from 'ui';
import { SectionService } from './shared/user/services/section.service';
import { ScriptService } from './shared/trackers/services/script.service';
import { GtagService } from './shared/trackers/services/gtag.service';
import { FbqService } from './shared/trackers/services/fbq.service';
import { GlamiService } from './shared/trackers/services/glami.service';
import { TrackerService } from './shared/trackers/services/tracker.service';
import { LoggerService } from './shared/trackers/services/logger.service';
import { Redirect } from './shared/models/redirect/redirect';
import { selectShopRedirect } from './shop/modules/redirect/get-redirect/reducers/get-redirect.reducer';

@Component({
  selector: 'mcrai-petgourmet',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy: Subject<null> = new Subject<null>();

  prevUrl: string;
  activeSection: string;
  isHeatmapOpened = false;
  redirect: Redirect;
  redirectComplete = false;

  readonly ADMIN = 'admin';
  readonly SHOP  = 'shop';

  constructor(
    private store: Store<any>,
    private router: Router,
    private renderer: Renderer2,
    private permissionService: PermissionService,
    private sectionService: SectionService,
    private toastService: McRAIToaster,
    private loggerService: LoggerService,
    private gtagService: GtagService,
    private fbqService: FbqService,
    private glamiService: GlamiService,
    private trackerService: TrackerService,
    private scriptService: ScriptService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy)
      )
      .subscribe((event: RouterEvent) => {
        switch (true) {
          case event instanceof NavigationStart:
            this.onNavigationStart(event as NavigationStart);
            break;
          case event instanceof NavigationEnd:
            this.gtagService
              .customParams('js', new Date())
              .customParams('config', this.gtagService.KEY_TAGMAN, { 'page_path': (event as NavigationEnd).urlAfterRedirects })
              .customParams('js', new Date())
              .customParams('config', this.gtagService.KEY_ADS);

            this.fbqService.track('PageView');
            this.glamiService.track('PageView');

            this.loggerService.view();

          // tslint:disable-next-line:no-switch-case-fall-through
          case event instanceof NavigationCancel:
            this.onNavigationEnd(this.prevUrl, (<NavigationEnd>event).url.split('?')[0]);
            break;
        }
      });

    this.store
      .pipe(
        select(selectShopRedirect),
        takeUntil(this.destroy)
      )
      .subscribe((value: Redirect) => {
        this.redirect = value;
        if (this.redirect) {
          this.redirectComplete = false;
          if (this.isUrlAbsolute(this.redirect.to)) {
            location.replace(this.redirect.to);
          } else {
            location.replace([location.protocol, '//', location.host, this.redirect.to].join(''));
          }
        } else {
          this.redirectComplete = true;
        }
      });

    this.store
      .pipe(
        select(state => state.sharedSection.activeSection),
        takeUntil(this.destroy),
        filter(value => !!value)
      )
      .subscribe((value: string) => {
        this.activeSection = value;

        this.renderer.removeClass(this.document.body, this.ADMIN);
        this.renderer.removeClass(this.document.body, this.SHOP);
        this.renderer.addClass(this.document.body, value);
      });
  }

  ngAfterViewInit(): void {
    this.IETest();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('window:click', ['$event'])
  private onClick(event): void {
    if (this.activeSection === this.SHOP && !this.isHeatmapOpened) {
      this.store.dispatch(new HeatmapActions.PostClick({
        page: this.router.url,
        point: [event.pageX, event.pageY, 1]
      }));
    }
  }

  private onNavigationStart(event: NavigationStart) {
    this.store.dispatch(new RedirectActions.GetShopRedirect({
      url: event.url
    }));

    this.store.dispatch(new SectionActions.SetActiveSection({
      section: this.sectionService.getSection(event, true),
    }));
  }

  private onNavigationEnd(prevUrl: string, currUrl: string) {
    if (prevUrl !== currUrl) {
      this.window.scrollTo(0, 0);
    }

    this.prevUrl = currUrl;
  }

  private IETest() {
    const isIE = /msie\s|trident/i.test(window.navigator.userAgent);

    if (isIE) {
      this.router.navigate(['cs', BROWSER_NOT_SUPPORTED]);
    }
  }

  private sendSklik(): void {
    this.scriptService.update({
      src: '//c.imedia.cz/js/retargeting.js',
      async: true,
    });
  }

  public showAdminPanel(): boolean {
    return this.activeSection ===  this.SHOP && this.permissionService.canReadPage('aegisx.logger');
  }

  public isUrlAbsolute(url: string): boolean {
    return (/^https?:\/\//).test(url);
  }
}
