import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  public send(tracker: Function, ...args): this {
    if (typeof tracker !== 'undefined') {
      tracker(...args);
    }

    return this;
  }
}
