import { Directive, HostBinding, HostListener, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, QueryParamsHandling, Router, UrlTree } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Category } from '../../models/category/category';
import { Product } from '../../models/product/product';
import { Article } from '../../models/article/article';
import { LinkService } from '../services/link.service';
import { Set } from '../../models/sets/set';

@Directive({
  selector: 'a[mcraiLink]'
})
export class LinkDirective implements OnChanges, OnDestroy {
  private destroy: Subject<null> = new Subject<null>();

  private _commands: any[] = [];
  private _isAbsolute: boolean;
  private _queryParams: {[k: string]: any};
  private _fragment: string;
  private _queryParamsHandling: QueryParamsHandling;
  private _preserveFragment: boolean;
  private _replaceUrl: boolean;
  private _skipLocationChange: boolean;

  @HostBinding('attr.target') private _target: string;
  @HostBinding('attr.href') private _href: string;

  @Input() set mcraiLink(commands: any[] | string | Category | Article | Product | Set) {
    this._commands = this._parseCommands(commands);
  }

  @Input() set href(value: string) {
    this._href = value;
  }

  @Input() set target(value: string) {
    this._target = value;
  }

  @Input() set queryParams(value: { [p: string]: any }) {
    this._queryParams = value;
  }

  @Input() set skipLocationChange(value: boolean) {
    this._skipLocationChange = value;
  }

  @Input() set replaceUrl(value: boolean) {
    this._replaceUrl = value;
  }

  @Input() set queryParamsHandling(value: QueryParamsHandling) {
    this._queryParamsHandling = value;
  }

  @Input() set fragment(value: string) {
    this._fragment = value;
  }

  get urlTree(): UrlTree {
    return this._router.createUrlTree(this._commands, {
      relativeTo: this._route,
      queryParams: this._queryParams,
      fragment: this._fragment,
      queryParamsHandling: this._queryParamsHandling,
      preserveFragment: coerceBooleanProperty(this._preserveFragment),
    });
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _locationStrategy: LocationStrategy,
    private _linkService: LinkService
  ) {
    _router.events
      .pipe(
        takeUntil(this.destroy),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this._updateTargetUrlAndHref());
  }

  ngOnChanges(): void {
    this._updateTargetUrlAndHref();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private _parseCommands(commands: any[] | string | Category | Article | Product | Set): any[] {
    switch (true) {
      case commands === null:
        return [];

      case Array.isArray(commands):
        return commands as any[];

      case typeof commands === 'string':
        return [commands];

      // entry at your own risk NotLikeThis
      case commands.hasOwnProperty('leaf'):
        if ((<Category>commands).info[0].is_link) {
          if ((<Category>commands).info[0].article_url) {
            return [this._linkService.getArticleLink((<Category>commands).info[0].article_url)];
          } else {
            this._isAbsolute = true;
            return [(<Category>commands).info[0].url];
          }
        } else {
          return [this._linkService.getCategoryLink(commands as Category)];
        }

      case commands.hasOwnProperty('date'):
        return [this._linkService.getArticleLink(commands as Article)];

      case commands.hasOwnProperty('required'):
        return [this._linkService.getSetLink(commands as Set)];

      default:
        return [this._linkService.getProductLink(commands as Product)];
    }
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
  private _onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }

    if (this._target && this._target !== '_self') {
      return true;
    }

    if (this._isAbsolute) {
      return true;
    }

    const extras = {
      skipLocationChange: coerceBooleanProperty(this._skipLocationChange),
      replaceUrl: coerceBooleanProperty(this._replaceUrl),
    };
    this._router.navigateByUrl(this.urlTree, extras);

    return false;
  }

  private _updateTargetUrlAndHref(): void {
    if (this._isAbsolute) {
      this._href = this._commands[0];
    } else {
      this._href = this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(this.urlTree));
    }
  }
}
