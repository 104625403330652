import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectProcessing } from './models/object-processing';
import { UrlProcessing } from './models/url-processing';
import { RandomGenerator } from './models/random-generator';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [
    ObjectProcessing,
    UrlProcessing,
    RandomGenerator,
  ]
})
export class UtilsModule { }
