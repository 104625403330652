import { Action } from '@ngrx/store';

export const POST_CLICK         = '[Heatmap] POST click';
export const POST_CLICK_SUCCESS = '[Heatmap] POST click success';
export const POST_CLICK_ERROR   = '[Heatmap] POST click error';

export class PostClick implements Action {
  readonly type = POST_CLICK;

  constructor(public payload: { page: string, point: [number, number, number] }) { }
}

export class PostClickSuccess implements Action {
  readonly type = POST_CLICK_SUCCESS;

  constructor() { }
}

export class PostClickError implements Action {
  readonly type = POST_CLICK_ERROR;

  constructor(public payload: { message: string }) { }
}

export type All
 = PostClick
 | PostClickSuccess
 | PostClickError;
