export enum Type { Success, Error, Info }

export namespace Type {
  export function keys(): { key: number, value: string }[] {

    return Object
      .keys(Type)
      .map(key => {
        const parsedKey = parseInt(key, 10);
        if (parsedKey >= 0) {
          return {
            key: parsedKey,
            value: Type[parsedKey]
          };
        }
      })
      .filter(key => !!key);
  }
}
