import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { GetClicks } from '../../../../heatmap/get-clicks/actions/get-clicks.actions';
import { selectHeatmapEntities } from '../../../../heatmap/get-clicks/reducers/get-clicks.reducer';

@Component({
  selector: 'shop-admin-panel',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  heatmapFlag = false;
  heatmapPoints: Observable<[number, number, number][]>;

  @Output() heatmapOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private store: Store<any>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.heatmapPoints = this.store
      .pipe(
        select(selectHeatmapEntities),
        filter(value => !!value[this.router.url]),
        map(value => value[this.router.url].points)
      );
  }

  private fetchClicks(): void {
    this.store.dispatch(new GetClicks({
      page: this.router.url
    }));
  }

  public toggleHeatmap(): void {
    this.heatmapFlag = !this.heatmapFlag;

    this.heatmapOpened.emit(this.heatmapFlag);

    if (this.heatmapFlag) {
      this.fetchClicks();
    }
  }

}
