// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { Environment } from './models/environment';

export const environment: Environment = {
    production: false,
    api: 'https://petgourmet.mcrai.eu/api/',
    // api: 'http://192.168.0.120/helvetia/helvetia/api/',
    // api: 'http://localhost/helvetia/helvetia/api/',
    root: 'http://localhost:4200/',
    // root_ssr: 'http://localhost:4200/',
};
