import { Action } from '@ngrx/store';
import { ErrorGui } from '../../models/errors/error-gui';

export const LOGOUT         = '[User] Logout';
export const LOGOUT_SUCCESS = '[User] Logout Success';
export const LOGOUT_ERROR   = '[User] Logout Error';

export const ADMIN_LOGOUT         = '[User] Admin Logout';
export const ADMIN_LOGOUT_SUCCESS = '[User] Admin Logout Success';
export const ADMIN_LOGOUT_ERROR   = '[User] Admin Logout Error';

export class Logout implements Action {
  readonly type = LOGOUT;

  constructor() { }
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;

  constructor(public payload: { message: string }) { }
}

export class LogoutError implements Action {
  readonly type = LOGOUT_ERROR;

  constructor(public payload: ErrorGui) { }
}


export class AdminLogout implements Action {
  readonly type = ADMIN_LOGOUT;

  constructor(public payload: { user_token: string, }) { }
}

export class AdminLogoutSuccess implements Action {
  readonly type = ADMIN_LOGOUT_SUCCESS;

  constructor(public payload: { message: string }) { }
}

export class AdminLogoutError implements Action {
  readonly type = ADMIN_LOGOUT_ERROR;

  constructor(public payload: ErrorGui) { }
}
