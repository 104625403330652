import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { McRAIToasterModule } from 'ui';
import { McRAILoaderModule } from 'ui';

@NgModule({
  imports: [
    CommonModule,
    McRAIToasterModule,
    McRAILoaderModule
  ],
  declarations: []
})
export class BaseHeatmapModule { }
