import { Action } from '@ngrx/store';

import { PostPasswordBody } from '../models/post-password-body';
import { McRAIModal } from 'ui';
import { ErrorGui } from '../../../../../shared/models/errors/error-gui';

export const POST_PASSWORD         = '[User Section] POST password';
export const POST_PASSWORD_SUCCESS = '[User Section] POST password success';
export const POST_PASSWORD_ERROR   = '[User Section] POST password error';

export class PostPassword implements Action {
  readonly type = POST_PASSWORD;

  constructor(public payload: { body: PostPasswordBody, resetPasswordModalService?: McRAIModal }) { }
}

export class PostPasswordSuccess implements Action {
  readonly type = POST_PASSWORD_SUCCESS;

  constructor(
    public payload: { message: string },
    public resetPasswordModalService?: McRAIModal
  ) { }
}

export class PostPasswordError implements Action {
  readonly type = POST_PASSWORD_ERROR;

  constructor(public payload: ErrorGui) { }
}

export type All
  = PostPassword
  | PostPasswordSuccess
  | PostPasswordError;
