import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { GetSearchHistoryEffects } from './effects/get-search-history.effects';
import { BaseHistoryModule } from '../base-history/base-history.module';
import { GetHistoryComponentsModule } from '../get-history-components/get-history-components.module';

import * as fromSearchHistory from './reducers/get-search-history.reducer';
import { SearchRecordListComponent } from './components/search-record-list/search-record-list.component';
import { SearchRecordListSmallComponent } from './components/search-record-list-small/search-record-list-small.component';

import { McRAIGridModule, McRAIInputModule, McRAITableModule } from 'ui';
import { McRAIListModule } from 'ui';

@NgModule({
  imports: [
    CommonModule,
    BaseHistoryModule,
    StoreModule.forFeature(fromSearchHistory.featureName, fromSearchHistory.reducer),
    EffectsModule.forFeature([
      GetSearchHistoryEffects
    ]),
    GetHistoryComponentsModule,
    McRAIGridModule,
    McRAIListModule,
    McRAITableModule,
    McRAIInputModule,
  ],
  declarations: [
    SearchRecordListSmallComponent,
    SearchRecordListComponent,
  ],
  exports: [
    SearchRecordListSmallComponent,
    SearchRecordListComponent,
  ],
})
export class GetSearchHistoryModule { }
