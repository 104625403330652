import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootComponent } from './components/root/root.component';
import { StoreModule } from '@ngrx/store';

import { McRAILoaderModule } from 'ui';

import * as fromLoader from './reducers/loader.reducer';

@NgModule({
  imports: [
    CommonModule,
    McRAILoaderModule,
    StoreModule.forFeature(fromLoader.featureName, fromLoader.reducer)
  ],
  declarations: [
    RootComponent
  ],
  exports: [
    RootComponent
  ]
})
export class LoaderModule { }
