import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../base/base.service';
import { Token } from '../models/token';
import { Credentials } from '../models/credentials';
import { User } from '../models/user';
import { RegistrationBody } from '../models/registration-body';
import { Permission } from '../models/permission';
import { UserResponse } from '../models/user-response';
import { VariablesEshop } from '../../models/settings/variables-eshop';
import { MessageResponse } from '../../base/message-response';

@Injectable()
export class UserService extends BaseService {
  public login(credentials: Credentials): Observable<UserResponse> {
    const endpoint = this.buildUrl(['login']);

    return this.post(endpoint, credentials);
  }

  public adminLogin(user_id: string): Observable<UserResponse> {
    const endpoint = this.buildUrl(['login', 'admin']);

    return this.post(endpoint, { user_id: user_id, });
  }

  public logout(): Observable<MessageResponse> {
    const endpoint = this.buildUrl(['logout']);

    return this.del(endpoint);
  }

  public adminLogout(user_token: string): Observable<MessageResponse> {
    const endpoint = this.buildUrl(['logout', 'admin', user_token]);

    return this.del(endpoint);
  }

  public authenticateAnonymous(): Observable<{
    user: User, token: Token, permissions: Permission[], variables: VariablesEshop
  }> {
    const endpoint = this.buildUrl(['login', 'anonym']);

    return this.post(endpoint, null);
  }

  public authenticate(): Observable<{
    user: User, token: Token, permissions: Permission[], origin: string, variables: VariablesEshop
  }> {
    const endpoint = this.buildUrl(['me']);

    return this.get(endpoint);
  }

  public authorize(route: string): Observable<{ allow: boolean, message?: string }> {
    const endpoint = this.buildUrl(['me', 'permission'], { route: route });

    return this.get(endpoint);
  }

  public register(body: RegistrationBody): Observable<UserResponse> {
    const endpoint = this.buildUrl(['register']);

    return this.post(endpoint, body);
  }

  public getHasUnconfirmedExports(): Observable<{ has_unconfirmed: boolean }> {
    const endpoint = this.buildUrl(['me', 'exports', 'unconfirmed']);

    return this.get(endpoint);
  }

}
