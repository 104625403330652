import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LinkDirective } from './directives/link.directive';
import { LinkService } from './services/link.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    LinkDirective
  ],
  providers: [
    LinkService
  ],
  exports: [
    LinkDirective,
    RouterModule
  ]
})
export class LinkModule { }
