import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BaseHistoryEffects } from '../../base-history/effects/base-history.effects';

import * as HistoryActions from '../actions/get-search-history.actions';
import { ToastType } from 'ui';
import { ErrorGuiAction } from '../../../../../shared/error/actions';

@Injectable()
export class GetSearchHistoryEffects extends BaseHistoryEffects {

  @Effect()
  getSearchHistory: Observable<Action> = this.actions
    .pipe(
      ofType(HistoryActions.GET_SEARCH_HISTORY),
      mergeMap((action: HistoryActions.GetSearchHistory) => {
        return this.dataService
          .getSearchHistory(action.payload.filter)
          .pipe(
            map(response => new HistoryActions.GetSearchHistorySuccess(response)),
            catchError(response => of(new HistoryActions.GetSearchHistoryError(response)))
          );
      }),
    );

  @Effect({ dispatch: false })
  getSearchHistorySuccess: Observable<Action> = this.actions
    .pipe(
      ofType(HistoryActions.GET_SEARCH_HISTORY_SUCCESS)
    );

  @Effect({ dispatch: false })
  getSearchHistoryError: Observable<Action> = this.actions
    .pipe(
      ofType(HistoryActions.GET_SEARCH_HISTORY_ERROR),
      tap((action: HistoryActions.GetSearchHistoryError) => {
        this.toastService.makeToast(action.payload.error.message, ToastType.Error);
      })
    );

  @Effect()
  getSearchHistorySmall: Observable<Action> = this.actions
    .pipe(
      ofType(HistoryActions.GET_SEARCH_HISTORY_SMALL),
      mergeMap((action: HistoryActions.GetSearchHistorySmall) => {
        return this.dataService
          .getSearchHistory(action.payload)
          .pipe(
            map(response => new HistoryActions.GetSearchHistorySmallSuccess(response)),
            catchError(response => of(new ErrorGuiAction(response)))
          );
      }),
    );

}
