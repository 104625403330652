import { environment } from '../environments/environment';
import { InjectionToken } from '@angular/core';
import { Token } from './shared/user/models/token';

export const API_URL = environment.api;
export const API_HTPASS = environment.htpasswd;
export const ROOT_URL = environment.root;
export const ROOT_URL_SSR = environment.root_ssr ? environment.root_ssr : null;
export const DOMAIN = environment.root.replace(/(^http:\/\/)|(^https:\/\/)|(:\d+)|(\/$)/g, '');
export const DOMAIN_SSR = environment.root_ssr
  ? environment.root_ssr.replace(/(^http:\/\/)|(^https:\/\/)|(:\d+)|(\/$)/g, '')
  : null;

export const WINDOW: InjectionToken<Window> = new InjectionToken<Window>('WINDOW');
// export const REQUEST: InjectionToken<any> = new InjectionToken<any>('REQUEST');
export const TOKEN: InjectionToken<Token> = new InjectionToken<string>('TOKEN');

export const IPP = 60;

export const ARTICLE_ROUTE                    = 'c';
export const CATEGORY_ROUTE                   = 'k';
export const PRODUCT_ROUTE                    = 'p';
export const SPECIAL_ACTION_PRODUCT_ROUTE     = 'sap';
export const BRAND_ROUTE                      = 'b';
export const SET_ROUTE                        = 'set';
export const COMPARATOR_ROUTE                 = 'porovnavac';
export const CART_ROUTE                       = 'kosik';
export const SITEMAP_ROUTE                    = 'mapa-stranek';
export const SEARCH                           = 'vyhledavani';
export const CONFIGURATOR                     = 'konfigurator';
export const WISHLIST                         = 'seznamy-prani';
export const SELLER_ROUTE                     = 'prodejny';
export const ERROR_404                        = 'error-404';
export const BROWSER_NOT_SUPPORTED            = 'nepodporovany-prohlizec';
export const CUSTOM_SET                       = 'vlastni-set';

export const USER_SECTION                     = 'k/uzivatelska-sekce';
export const US_PROFILE                       = 'profil';
export const US_MESSAGES                      = 'zpravy';
export const US_EXPORTS                       = 'vydejky';
export const US_HISTORY                       = 'historie';
