import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { BaseRedirectEffects } from '../../base-redirect/effects/base-redirect.effects';

import * as RedirectActions from '../actions/get-redirect.actions';

@Injectable()
export class GetShopRedirectEffects extends BaseRedirectEffects {

  @Effect()
  getShopRedirect: Observable<Action> = this.actions
    .pipe(
      ofType(RedirectActions.GET_SHOP_REDIRECT),
      switchMap((action: RedirectActions.GetShopRedirect) =>
        this.redirectService
          .getShopRedirect(action.payload.url)
          .pipe(
            map((response: any) => new RedirectActions.GetShopRedirectSuccess(response))
          )
      )
    );

  @Effect({ dispatch: false })
  getShopRedirectSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(RedirectActions.GET_SHOP_REDIRECT_SUCCESS)
    );
}
