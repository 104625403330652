import { Injectable } from '@angular/core';
import { Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BaseHeatmapEffects } from '../../base-heatmap/effects/base-heatmap.effects';

import * as ModuleActions from '../actions/get-clicks.actions';

@Injectable()
export class GetClicksEffects extends BaseHeatmapEffects {

  @Effect()
  getClicks: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.GET_CLICKS),
      tap(() => this.loaderService.show()),
      switchMap((action: ModuleActions.GetClicks) => {
        return this.dataService
          .getPoints(action.payload.page)
          .pipe(
            map((result: any) => new ModuleActions.GetClicksSuccess(result)),
            catchError(result => of(new ModuleActions.GetClicksError(result)))
          );
      })
    );

  @Effect({ dispatch: false })
  getClicksSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.GET_CLICKS_SUCCESS),
      tap((action: ModuleActions.GetClicksSuccess) => {
        this.loaderService.hide();
      })
    );

  @Effect({ dispatch: false })
  getClicksError: Observable<Action> = this.actions
    .pipe(
      ofType(ModuleActions.GET_CLICKS_ERROR),
      tap((action: ModuleActions.GetClicksError) => {
        this.loaderService.hide();
      })
    );

}
