import { Action } from '@ngrx/store';

export const SHOW_LOADER = '[Loader] SHOW loader';
export const HIDE_LOADER = '[Loader] HIDE loader';

export const RESET_LOADER = '[Loader] RESET loader';

export class ShowLoader implements Action {
  readonly type = SHOW_LOADER;

  constructor() { }
}

export class HideLoader implements Action {
  readonly type = HIDE_LOADER;

  constructor() { }
}

export class ResetLoader implements Action {
  readonly type = RESET_LOADER;

  constructor() { }
}

export type All
  = ShowLoader
  | HideLoader
  | ResetLoader;
