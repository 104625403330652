import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type ScriptConfig = Partial<{
  id: string;
  src: string;
  async: boolean;
  text: string;
  iframe: boolean;
}>;

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public find(config: ScriptConfig): HTMLScriptElement {
    if (!config.id && !config.src) {
      console.log('Tracker script must be identified either by id or by source URL');
      return;
    }

    return this.document.querySelector(
      config.id ? `#${config.id}` : `script[src="${config.src}"]`
    );
  }

  public update(config: ScriptConfig): void {
    this.remove(config);

    if (config.iframe) {
      const iframe = this.renderer.createElement('iframe');
    }

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';

    Object.assign(script, config);

    this.renderer.appendChild(this.document.body, script);
  }

  public remove(config: ScriptConfig): boolean {
    const el: HTMLScriptElement = this.find(config);

    if (el) {
      el.remove();
      return true;
    }

    return false;
  }
}
