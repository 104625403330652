import { Component, OnInit } from '@angular/core';
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';
import { ModalOptions, McRAIModal } from 'ui';

@Component({
  selector: 'shop-reset-password-button',
  templateUrl: './reset-password-button.component.html',
  styleUrls: ['./reset-password-button.component.scss']
})
export class ResetPasswordButtonComponent implements OnInit {

  constructor(
    private modalService: McRAIModal
  ) { }

  ngOnInit(): void { }

  public openResetPasswordModal(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const options = new ModalOptions('500px', '250px', '', false, true);
    this.modalService.open(ResetPasswordModalComponent, options);
  }
}
