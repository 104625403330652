import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../../../../shared/base/base.service';
import { Address } from '../../../../../shared/user/models/address';
import { PatchUserBody } from '../../patch-user/models/patch-user-body';
import { User } from '../../../../../shared/user/models/user';
import { Paper } from '../../../../../shared/models/users/paper';
import { Container } from '../../../../../shared/models/shared/container';
import { DocumentsFilter } from '../../get-documents/models/documents-filter';
import { PatchPasswordBody } from '../../patch-password/models/patch-password-body';
import { PostPasswordBody } from '../../post-password/models/post-password-body';
import { MessageResponse } from '../../../../../shared/base/message-response';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {

  public postAddress(address: Address): Observable<any> {
    const endpoint = this.buildUrl(['me', 'addresses']);

    return this.post(endpoint, address);
  }

  public patchAddresses(addresses: Address[]): Observable<any> {
    const endpoint = this.buildUrl(['me', 'addresses']);

    return this.patch(endpoint, addresses);
  }

  public deleteAddresses(ids: string[]): Observable<any> {
    const endpoint = this.buildUrl(['me', 'addresses'], { address_ids: ids });

    return this.del(endpoint);
  }

  public patchUser(body: PatchUserBody): Observable<{ user: User, message: string }> {
    const endpoint = this.buildUrl(['me']);

    return this.patch(endpoint, body);
  }

  public getReclamations(page: number, filter: DocumentsFilter): Observable<Container<Paper>> {
    const params = {
      page: page
    };
    const endpoint = this.buildUrl(['me', 'documents'], Object.assign(params, filter));

    return this.get(endpoint);
  }

  public getInvoices(page: number, filter: DocumentsFilter): Observable<Container<Paper>> {
    const params = {
      page: page
    };
    const endpoint = this.buildUrl(['me', 'invoices'], Object.assign(params, filter));

    return this.get(endpoint);
  }

  public getExporters(page: number, filter: DocumentsFilter): Observable<Container<Paper>> {
    const params = {
      page: page
    };
    const endpoint = this.buildUrl(['me', 'exports'], Object.assign(params, filter), false, true, false);

    return this.get(endpoint);
  }

  public patchExports(id: string, note?: string): Observable<{ message: string, code: string, state: { id: number, note: string} }> {
    const endpoint = this.buildUrl(['me', 'exports', id]);

    return this.patch(endpoint, {note: note});
  }

  public patchAllExports(
    page: number, filter: DocumentsFilter
  ): Observable<{ message: string, cnt_confirmed: number, exporters: Container<Paper> }> {
    const endpoint = this.buildUrl(['me', 'exports']);
    const body = this.objProc.deleteEmptyKeys(
      Object.assign({ page: page }, filter), { let_zero: true }
    );

    return this.patch(endpoint, body);
  }

  public patchPassword(body: PatchPasswordBody): Observable<MessageResponse> {
    const endpoint = this.buildUrl(['password']);

    return this.patch(endpoint, body);
  }

  public postPassword(body: PostPasswordBody): Observable<MessageResponse> {
    const endpoint = this.buildUrl(['password']);

    return this.post(endpoint, body);
  }

}
