import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { PostClickEffects } from './effects/post-click.effects';
import { BaseHeatmapModule } from '../base-heatmap/base-heatmap.module';

@NgModule({
  imports: [
    CommonModule,
    BaseHeatmapModule,
    EffectsModule.forFeature([
      PostClickEffects
    ])
  ],
  declarations: []
})
export class PostClickModule { }
