import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import * as fromSection from './reducers/section.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('sharedSection', fromSection.reducer)
  ],
  declarations: []
})
export class SectionModule { }
