import { Injectable } from '@angular/core';

declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FbqService {

  private static checkFbq(): boolean {
    return typeof fbq !== 'undefined';
  }

  public track(event: string): this {
    if (FbqService.checkFbq()) {
      fbq('track', event);
    }

    return this;
  }

  public customParams(...args): this {
    if (FbqService.checkFbq()) {
      fbq(...args);
    }

    return this;
  }
}
