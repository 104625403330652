import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charLimitWholeWords'
})
export class CharLimitWholeWordsPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    if (value && value.length > limit) {
      const end = value.indexOf(' ', limit);
      if (end > 0) {
        return  value.substr(0, end) + '…';
      }
    }
    return value;
  }

}
