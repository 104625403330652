import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { BaseRedirectModule } from '../base-redirect/base-redirect.module';
import { GetShopRedirectEffects } from './effects/get-redirect.effects';

import * as fromRedirect from './reducers/get-redirect.reducer';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    BaseRedirectModule,
    EffectsModule.forFeature([
      GetShopRedirectEffects
    ]),
    StoreModule.forFeature(fromRedirect.featureName, fromRedirect.reducer)
  ],
  declarations: []
})
export class GetRedirectModule { }
