import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticateGuard } from './shared/user/guards/authenticate.guard';
import {
  ARTICLE_ROUTE,
  CATEGORY_ROUTE,
  COMPARATOR_ROUTE,
  PRODUCT_ROUTE,
  SPECIAL_ACTION_PRODUCT_ROUTE,
  CART_ROUTE,
  SITEMAP_ROUTE,
  SEARCH,
  CONFIGURATOR,
  USER_SECTION,
  WISHLIST,
  ERROR_404,
  BROWSER_NOT_SUPPORTED,
  CUSTOM_SET,
  SET_ROUTE,
  BRAND_ROUTE,
  SELLER_ROUTE
} from './app.config';

const routes: Routes = [
  // aegisx
  {
    path: 'aegisx',
    redirectTo: 'aegisx/login',
    pathMatch: 'full',
  }, {
    path: 'aegisx/login',
    loadChildren: () => import('./aegisx/pages/_login/login.module').then(m => m.LoginModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/dashboard',
    loadChildren: () => import('./aegisx/pages/_dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/categories',
    loadChildren: () => import('./aegisx/pages/_categories/categories.module').then(m => m.CategoriesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/category-trees',
    loadChildren: () => import('./aegisx/pages/_category-trees/category-trees.module').then(m => m.CategoryTreesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/products',
    loadChildren: () => import('./aegisx/pages/_products/products.module').then(m => m.ProductsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/brands',
    loadChildren: () => import('./aegisx/pages/_brands/brands.module').then(m => m.BrandsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/types',
    loadChildren: () => import('./aegisx/pages/_types/types.module').then(m => m.TypesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/product-set-relation-types',
    loadChildren: () => import('./aegisx/pages/_product-set-relation-types/product-set-relation-types.module').then(m => m.ProductSetRelationTypesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/store-states',
    loadChildren: () => import('./aegisx/pages/_store-states/store-states.module').then(m => m.StoreStatesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/price-levels',
    loadChildren: () => import('./aegisx/pages/_price-levels/price-levels.module').then(m => m.PriceLevelsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/users',
    loadChildren: () => import('./aegisx/pages/_users/users.module').then(m => m.UsersModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/articles',
    loadChildren: () => import('./aegisx/pages/_articles/articles.module').then(m => m.ArticlesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/settings',
    loadChildren: () => import('./aegisx/pages/_settings/settings.module').then(m => m.SettingsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/import',
    loadChildren: () => import('./aegisx/pages/_import/import.module').then(m => m.ImportModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/orders',
    loadChildren: () => import('./aegisx/pages/_orders/orders.module').then(m => m.OrdersModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/banners',
    loadChildren: () => import('./aegisx/pages/_banners/banners.module').then(m => m.BannersModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/export',
    loadChildren: () => import('./aegisx/pages/_export/export.module').then(m => m.ExportModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/params',
    loadChildren: () => import('./aegisx/pages/_params/params.module').then(m => m.ParamsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/messages',
    loadChildren: () => import('./aegisx/pages/_messages/messages.module').then(m => m.MessagesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/permissions',
    loadChildren: () => import('./aegisx/pages/_permissions/permissions.module').then(m => m.PermissionsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/courses',
    loadChildren: () => import('./aegisx/pages/_courses/courses.module').then(m => m.CoursesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/discounts',
    loadChildren: () => import('./aegisx/pages/_discounts/discounts.module').then(m => m.DiscountsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/price-actions',
    loadChildren: () => import('./aegisx/pages/_price-actions/price-actions.module').then(m => m.PriceActionsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/action-currencies',
    loadChildren: () => import('./aegisx/pages/_action-currencies/action-currencies.module').then(m => m.ActionCurrenciesModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/sets',
    loadChildren: () => import('./aegisx/pages/_sets/sets.module').then(m => m.SetsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/feedbacks',
    loadChildren: () => import('./aegisx/pages/_feedbacks/feedbacks.module').then(m => m.FeedbacksModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/login-history',
    loadChildren: () => import('./aegisx/pages/_login-history/login-history.module').then(m => m.LoginHistoryModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/contacts',
    loadChildren: () => import('./aegisx/pages/_contacts/contacts.module').then(m => m.ContactsModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/newsletters',
    loadChildren: () => import('./aegisx/pages/_newsletters/newsletters.module').then(m => m.NewslettersModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/sellers',
    loadChildren: () => import('./aegisx/pages/_sellers/sellers.module').then(m => m.SellersModule),
    canLoad: [
      AuthenticateGuard
    ],
  }, {
    path: 'aegisx/redirects',
    loadChildren: () => import('./aegisx/pages/_redirects/redirects.module').then(m => m.RedirectsModule),
    canLoad: [
      AuthenticateGuard
    ],
  },

  // CZ shop
  {
    path: '',
    redirectTo: 'cs',
    pathMatch: 'full',
  }, {
    path: ':language/' + CART_ROUTE,
    loadChildren: () => import('./shop/pages/_cart/cart.module').then(m => m.CartModule),
    canLoad: [
      AuthenticateGuard,
    ]
  }, {
    path: ':language/' + SITEMAP_ROUTE,
    loadChildren: () => import('./shop/pages/_sitemap/sitemap.module').then(m => m.SitemapModule),
    canLoad: [
      AuthenticateGuard,
    ]
  }, {
    path: ':language/' + COMPARATOR_ROUTE,
    loadChildren: () => import('./shop/pages/_comparator/comparator.module').then(m => m.ComparatorModule),
    canLoad: [
      AuthenticateGuard,
    ]
  }, {
    path: ':language/' + USER_SECTION ,
    loadChildren: () => import('./shop/pages/_user-section/user-section.module').then(m => m.UserSectionModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + CATEGORY_ROUTE,
    loadChildren: () => import('./shop/pages/_category/category.module').then(m => m.CategoryModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + PRODUCT_ROUTE,
    loadChildren: () => import('./shop/pages/_product/product.module').then(m => m.ProductModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + SPECIAL_ACTION_PRODUCT_ROUTE,
    loadChildren: () => import('./shop/pages/_special_action_product/special-action-product.module').then(m => m.SpecialActionProductModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + BRAND_ROUTE,
    loadChildren: () => import('./shop/pages/_brand/brand.module').then(m => m.BrandModule),
    canLoad: [
      AuthenticateGuard
    ]
  },
  {
    path: ':language/' + SET_ROUTE,
    loadChildren: () => import('./shop/pages/_set/set.module').then(m => m.SetModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + ARTICLE_ROUTE,
    loadChildren: () => import('./shop/pages/_article/article.module').then(m => m.ArticleModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + SEARCH,
    loadChildren: () => import('./shop/pages/_search/search.module').then(m => m.SearchModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + CONFIGURATOR ,
    loadChildren: () => import('./shop/pages/_configurator/configurator.module').then(m => m.ConfiguratorModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + WISHLIST ,
    loadChildren: () => import('./shop/pages/_wishlist/wishlist.module').then(m => m.WishlistModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + SELLER_ROUTE ,
    loadChildren: () => import('./shop/pages/_seller/seller.module').then(m => m.SellerModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + ERROR_404 ,
    loadChildren: () => import('./shop/pages/_404/404.module').then(m => m.Error404Module),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + BROWSER_NOT_SUPPORTED ,
    loadChildren: () => import('./shop/pages/_browser-not-supported/browser-not-supported.module').then(m => m.BrowserNotSupportedModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language/' + CUSTOM_SET ,
    loadChildren: () => import('./shop/pages/_custom-set/custom-set.module').then(m => m.CustomSetModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: ':language',
    loadChildren: () => import('./shop/pages/_homepage/homepage.module').then(m => m.HomepageModule),
    canLoad: [
      AuthenticateGuard
    ]
  }, {
    path: '**',
    redirectTo: 'cs/' + ERROR_404
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false }),
  ],
  exports: [
    RouterModule,
  ],
  declarations: [],
})
export class AppRoutingModule { }
