import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { skip } from 'rxjs/operators';

import { McRAIToaster } from 'ui';

@Component({
  selector: 'shared-message-anchor',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor(
    private store: Store<any>,
    private toastService: McRAIToaster
  ) { }

  ngOnInit(): void {
    this.store
      .pipe(
        select(state => state.sharedMessage),
        skip(1)
      )
      .subscribe(message => this.onMessageChange(message));
  }

  onMessageChange(message) {
    if (message.hasOwnProperty('duration')) {
      this.toastService.makeToast(message.text, message.type, message.duration);
    } else {
      this.toastService.makeToast(message.text, message.type);
    }
  }
}
