import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseHistoryModule } from '../base-history/base-history.module';

import { GetHistoryHeaderCellComponent } from './components/get-history-header-cell/get-history-header-cell.component';

@NgModule({
  imports: [
    CommonModule,
    BaseHistoryModule,
  ],
  declarations: [
    GetHistoryHeaderCellComponent,
  ],
  exports: [
    GetHistoryHeaderCellComponent,
  ],
})
export class GetHistoryComponentsModule { }
