import { Injectable } from '@angular/core';

declare let glami: Function;

@Injectable({
  providedIn: 'root'
})
export class GlamiService {

  private static checkGlami(): boolean {
    return typeof glami !== 'undefined';
  }

  public track(event: string): this {
    if (GlamiService.checkGlami()) {
      glami('track', event);
    }

    return this;
  }

  public customParams(...args): this {
    if (GlamiService.checkGlami()) {
      glami(...args);
    }

    return this;
  }
}
