import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackToTopButtonComponent } from './components/back-to-top-button/back-to-top-button.component';
import { McRAIButtonModule, McRAIIconModule } from 'ui';

@NgModule({
  imports: [
    CommonModule,
    McRAIButtonModule,
    McRAIIconModule
  ],
  declarations: [
    BackToTopButtonComponent
  ],
  exports: [
    BackToTopButtonComponent
  ]
})
export class BackToTopModule { }
