import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GetSearchHistory } from '../../actions/get-search-history.actions';
import { SearchRecord } from '../../../../../../shared/models/history/search-record';
import { selectSearchHistory, selectSearchHistoryCntAll } from '../../reducers/get-search-history.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkService } from '../../../../../../shared/link/services/link.service';
import { AssocArray } from '../../../../../../shared/models/base/assoc-array';
import { RecordList } from '../../../models/record-list';

@Component({
  selector: 'shop-search-record-list',
  templateUrl: './search-record-list.component.html',
  styleUrls: ['./search-record-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRecordListComponent extends RecordList implements OnInit {
  records: Observable<SearchRecord[]>;

  constructor(
    protected store: Store<any>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    public linkService: LinkService,
  ) {
    super(store, activatedRoute, router);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.records = this.store
      .pipe(
        select(selectSearchHistory)
      );

    this.cnt_all = this.store
      .pipe(
        select(selectSearchHistoryCntAll)
      );
  }

  protected fetchHistory(filter?: AssocArray): void {
    this.store.dispatch(new GetSearchHistory({
      filter: filter,
    }));
  }

}
