import { Map } from 'immutable';

import * as RedirectActions from '../actions/get-redirect.actions';

import { Redirect } from '../../../../../shared/models/redirect/redirect';

export interface State {
  redirect: Map<string, Redirect>;
}

export const initialState: State = {
  redirect: null,
};

export const featureName = 'shopRedirect';

export function reducer(state = initialState, action: RedirectActions.All) {
  switch (action.type) {
    case RedirectActions.GET_SHOP_REDIRECT_SUCCESS:
      return {
        ...state,
        redirect: action.payload.item,
      };
    default: {
      return state;
    }
  }
}

export const selectShopRedirect = state => state[featureName].redirect;
