import { Action } from '@ngrx/store';

import { ErrorGui } from '../../../../../shared/models/errors/error-gui';
import { SearchRecord } from '../../../../../shared/models/history/search-record';
import { AssocArray } from '../../../../../shared/models/base/assoc-array';

export const GET_SEARCH_HISTORY         = '[History] GET search history';
export const GET_SEARCH_HISTORY_SUCCESS = '[History] GET search history success';
export const GET_SEARCH_HISTORY_ERROR   = '[History] GET search history error';

export const GET_SEARCH_HISTORY_SMALL         = '[History] GET search history small';
export const GET_SEARCH_HISTORY_SMALL_SUCCESS = '[History] GET search history small success';

export class GetSearchHistory implements Action {
  readonly type = GET_SEARCH_HISTORY;

  constructor(public payload: { filter?: AssocArray }) { }
}

export class GetSearchHistorySuccess implements Action {
  readonly type = GET_SEARCH_HISTORY_SUCCESS;

  constructor(public payload: { history: SearchRecord[], cnt_all: number }) { }
}

export class GetSearchHistoryError implements Action {
  readonly type = GET_SEARCH_HISTORY_ERROR;

  constructor(public payload: ErrorGui) { }
}


export class GetSearchHistorySmall implements Action {
  readonly type = GET_SEARCH_HISTORY_SMALL;

  constructor(public payload: { ipp: number, }) { }
}

export class GetSearchHistorySmallSuccess implements Action {
  readonly type = GET_SEARCH_HISTORY_SMALL_SUCCESS;

  constructor(public payload: { history: SearchRecord[], cnt_all: number }) { }
}


export type All
  = GetSearchHistory
  | GetSearchHistorySuccess
  | GetSearchHistoryError
  | GetSearchHistorySmall
  | GetSearchHistorySmallSuccess
;
