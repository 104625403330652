import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'get-history-header-cell',
  templateUrl: './get-history-header-cell.component.html',
  styleUrls: ['./get-history-header-cell.component.scss'],
})
export class GetHistoryHeaderCellComponent {

  @Input() order_by: string;
  @Input() order: string;

  @Input() column: string;
  @Input() column_name: string;

  @Output() orderByColumn: EventEmitter<string> = new EventEmitter();

  constructor() { }

  public orderByMe(event: MouseEvent): void {
    event.preventDefault();

    this.orderByColumn.emit(this.column);
  }

}
