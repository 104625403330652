import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../../../../shared/base/base.service';
import { Redirect } from '../../../../../shared/models/redirect/redirect';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {

  public getShopRedirect(url: string): Observable<{ item: Redirect }> {
    const get = {
      url: url
    };

    const endpoint = this.buildUrl(['shop', 'redirect'], get);
    return this.get(endpoint);
  }

}
