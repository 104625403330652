import { Action } from '@ngrx/store';
import { Type } from '../models/type';

export const SHOW_MESSAGE = '[Message] SHOW message';
export const HIDE_MESSAGE = '[Message] HIDE message';

export class ShowMessage implements Action {
  readonly type = SHOW_MESSAGE;

  constructor(public payload: { text: string, type: Type, duration?: number }) { }
}

export class HideMessage implements Action {
  readonly type = HIDE_MESSAGE;

  constructor() { }
}

export type All
  = ShowMessage
  | HideMessage;
