import { Action } from '@ngrx/store';

import { ShopOrder } from '../../../../../shared/models/order/shop-order';

export const GET_CART_ORDER         = '[Carts] GET cart order';
export const GET_CART_ORDER_SUCCESS = '[Carts] GET cart order success';
export const GET_CART_ORDER_ERROR   = '[Carts] GET cart order error';

export class GetCartOrder implements Action {
  readonly type = GET_CART_ORDER;

  constructor() { }
}

export class GetCartOrderSuccess implements Action {
  readonly type = GET_CART_ORDER_SUCCESS;

  constructor(public payload: {
    order: ShopOrder, displayError?: boolean, errorMessage?: string
  }) { }
}

export class GetCartOrderError implements Action {
  readonly type = GET_CART_ORDER_ERROR;

  constructor(public payload: { message: string }) { }
}

export type All
  = GetCartOrder
  | GetCartOrderSuccess
  | GetCartOrderError;
