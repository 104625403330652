import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Action, select } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Type } from '../../message/models/type';
import { UserEffects } from './user.effects';

import * as MessageActions from '../../message/actions/message.actions';
import * as UserActions from '../actions/user.actions';
import { isAdmin, selectAuthenticated } from '../reducers/user.reducer';
import { User } from '../models/user';
import { ToastType } from 'ui';

@Injectable()
export class LogoutEffects extends UserEffects {

  @Effect()
  logout: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGOUT),
      tap(() => {
        this.loaderService.show();
      }),
      switchMap((/*action: UserActions.Logout*/) => {
        return this.userService
          .logout()
          .pipe(
            map(response => new UserActions.LogoutSuccess(response)),
            catchError(response => of(new UserActions.LogoutError(response)))
          );
        }
      )
    );

  @Effect()
  logoutSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGOUT_SUCCESS),
      tap(() => this.loaderService.hide()),
      withLatestFrom(
        this.store.pipe(
          select(state => state.sharedSection.activeSection)
        ),
        this.store.pipe(
          select(selectAuthenticated),
          filter((user: User) => !!user),
        ),
      ),
      switchMap(([action, activeSection, user]: [UserActions.LogoutSuccess, string, User]) => {
        this.toasterService.eatThemAll();

        const section = isAdmin(user)
          ? this.sectionService.ALL
          : this.sectionService.SHOP;

        const actions: Action[] = [
          new UserActions.RemoveToken({
            section: section,
          }),
          new UserActions.AuthenticateAnonymous({ afterLogout: true }),
          new MessageActions.ShowMessage({
            text: action.payload.message,
            type: Type.Success
          })
        ];

        if (activeSection === 'admin') {
          actions.push(new UserActions.LoginRedirect({ url: '/aegisx/login' }));
        } else {
          // actions.push(new UserActions.LoginRedirect({ url: '/cs' }));
        }

        return actions;
      })
    );

  @Effect({ dispatch: false })
  logoutError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGOUT_ERROR),
      tap((action: UserActions.LogoutError) => {
        this.toasterService.makeToast(action.payload.message, ToastType.Error);
        this.loaderService.hide();
      })
    );


  @Effect()
  adminLogout: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.ADMIN_LOGOUT),
      mergeMap((action: UserActions.AdminLogout) => {
        return this.userService
          .adminLogout(action.payload.user_token)
          .pipe(
            map(response => new UserActions.AdminLogoutSuccess(response)),
            catchError(response => of(new UserActions.AdminLogoutError(response)))
          );
        }
      )
    );

  @Effect({ dispatch: false })
  adminLogoutSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.ADMIN_LOGOUT_SUCCESS),
      tap((action: UserActions.AdminLogoutSuccess) => {
        this.toasterService.eatThemAll();
        // this.toasterService.makeToast(action.payload.message, ToastType.Success);
      })
    );

  @Effect({ dispatch: false })
  adminLogoutError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.ADMIN_LOGOUT_ERROR),
      tap((action: UserActions.AdminLogoutError) => {
        // this.toasterService.makeToast(action.payload.error.message, ToastType.Error);
      })
    );

}

