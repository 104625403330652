import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notSet'
})
export class NotSetPipe implements PipeTransform {
  static readonly LET = {
    ZERO:      0x01,
    EMPTY_STR: 0x02,
    FALSE:     0x04,
    NAN:       0x08,
  };

  transform(value: any, allow: number = 0x00): any {
    if (
      (allow & NotSetPipe.LET.ZERO)         && value === 0
      || (allow & NotSetPipe.LET.EMPTY_STR) && value === ''
      || (allow & NotSetPipe.LET.FALSE)     && value === false
      || (allow & NotSetPipe.LET.NAN)       && isNaN(value)
    ) {
      return value;
    }

    return value || '-';
  }

}
