import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Action, select } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { UserEffects } from './user.effects';
import { ToastType } from 'ui';

import * as UserActions from '../actions/user.actions';
import * as ComparatorActions from '../../../shop/modules/comparator/get-comparator-products/actions/get-comparator-products.actions';
import * as MessagesActions from '../../../shop/modules/messages/get-messages/actions/get-messages.actions';
import * as CartActions from '../../../shop/modules/carts/get-carts/actions/get-carts.actions';
import { isPlatformBrowser } from '@angular/common';
import { GetCartOrder } from '../../../shop/modules/carts/get-cart-order/actions/get-cart-order.actions';
import { AdminLogout, GetHasUnconfirmedExports } from '../actions/user.actions';
import { isAdmin } from '../reducers/user.reducer';
import { GetSearchHistorySmall } from '../../../shop/modules/history/get-search-history/actions/get-search-history.actions';
import { GetWishlists } from '../../../shop/modules/wishlist/get-wishlists/actions/get-wishlists.actions';

@Injectable()
export class LoginEffects extends UserEffects {

  @Effect()
  login: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGIN),
      tap(() => this.loaderService.show()),
      switchMap((action: UserActions.Login) =>
        this.userService
          .login(action.payload.credentials)
          .pipe(
            map(response => {
              return new UserActions.LoginSuccess(
                response, action.payload.loginModalService
              );
            }),
            catchError(response => of(new UserActions.LoginError(response)))
          )
      )
    );

  @Effect()
  loginSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGIN_SUCCESS),
      withLatestFrom(
        this.store.pipe(
          select(state => state.sharedSection.activeSection)
        )
      ),
      switchMap(([action, activeSection]: [UserActions.LoginSuccess, string]) => {
        if (action.loginModalService) {
          action.loginModalService.close();
        }

        const section = isAdmin(action.payload.user)
          ? this.sectionService.ALL
          : this.sectionService.SHOP;

        const actions = [
          new UserActions.SaveToken({
            token: action.payload.token,
            section: section,
          }),
        ] as Action[];

        switch (activeSection) {
          case 'admin':
            actions.push(new UserActions.LoginRedirect({ url: '/aegisx/dashboard' }));
            break;
          case 'shop':
            actions.push(
              new CartActions.GetCarts(),
              new GetCartOrder(),
              new GetWishlists(),
              new ComparatorActions.GetProducts(),
              new MessagesActions.GetMessages({ id: 'user', page: 1 }),
              new GetHasUnconfirmedExports(),
              new GetSearchHistorySmall({ ipp: 5 })
            );
            break;
        }

        return actions;
      }),
      tap((/*action: UserActions.LoginSuccess*/) => {
        this.loaderService.hide();
      })
    );

  @Effect({ dispatch: false })
  loginError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGIN_ERROR),
      tap((action: UserActions.LoginError) => {
        this.loaderService.hide();
        this.toasterService.makeToast(action.payload.error.message, ToastType.Error);
      })
    );

  @Effect({ dispatch: false })
  loginRedirect: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.LOGIN_REDIRECT),
      tap((action: UserActions.LoginRedirect) => {
        if (action.payload.new_window) {
          this.window.open(action.payload.url, '_blank');
        } else {
          this.router.navigateByUrl(action.payload.url);
        }
      })
    );


  @Effect()
  adminLogin: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.ADMIN_LOGIN),
      switchMap((action: UserActions.AdminLogin) =>
        this.userService
          .adminLogin(action.payload.user_id)
          .pipe(
            map(response => new UserActions.AdminLoginSuccess(response)),
            catchError(response => of(new UserActions.AdminLoginError(response)))
          )
      )
    );

  @Effect()
  adminLoginSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.ADMIN_LOGIN_SUCCESS),
      switchMap((action: UserActions.AdminLoginSuccess) => {
        const actions = [];
        const section = isAdmin(action.payload.user)
          ? this.sectionService.ALL
          : this.sectionService.SHOP;

        if (isPlatformBrowser(this.platformId)) {
          const tokens = this.browserTokenService.get(section);
          const admin_token = this.browserTokenService.getOne(this.sectionService.ADMIN);

          for (const t_section of Object.keys(tokens)) {
            if (
              t_section !== this.sectionService.ADMIN
              && tokens[t_section].id === admin_token.id
              && action.payload.user.role.name !== 'aegisx'
            ) {
              console.log(t_section);
              console.log(this.sectionService.ADMIN);
              console.log(tokens[t_section].id);
              console.log(admin_token.id);
              continue;
            }

            console.log(tokens[t_section]);
            actions.push(
              new AdminLogout({ user_token: tokens[t_section].id, })
            );
          }
        }

        return actions.concat([
          new UserActions.SaveToken({
            token: action.payload.token,
            section: section,
          }),
          new UserActions.LoginRedirect({ url: '/', new_window: true, }),
        ]);
      })
    );

  @Effect({ dispatch: false })
  adminLoginError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.ADMIN_LOGIN_ERROR),
      tap((action: UserActions.AdminLoginError) => {
        this.toasterService.makeToast(action.payload.error.message, ToastType.Error);
      })
    );


  @Effect({ dispatch: false })
  saveToken: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.SAVE_TOKEN),
      tap((action: UserActions.SaveToken) => {
        if (isPlatformBrowser(this.platformId)) {
          this.browserTokenService.set(action.payload.token, action.payload.section);
        }
      })
    );

  @Effect({ dispatch: false })
  removeToken: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.REMOVE_TOKEN),
      tap((action: UserActions.RemoveToken) => {
        if (isPlatformBrowser(this.platformId)) {
          this.browserTokenService.delete(action.payload.section);
        }
      })
    );
}
