import { Component, Inject, OnInit } from '@angular/core';

import { McRAIModal } from 'ui';
import { MCRAI_MODAL_DATA } from 'ui';

@Component({
  selector: 'shop-unconfirmed-exports-modal',
  templateUrl: './unconfirmed-exports-modal.component.html',
  styleUrls: ['./unconfirmed-exports-modal.component.scss']
})
export class UnconfirmedExportsModalComponent implements OnInit {

  constructor(
    private modalService: McRAIModal,
    @Inject(MCRAI_MODAL_DATA) public data: { text: string, header: string }
  ) { }

  ngOnInit(): void { }

  public closeModal(): void {
    this.modalService.close();
  }
}
