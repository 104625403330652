import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'characterLimit'
})
export class CharacterLimitPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    return value && value.length > limit ? value.slice(0, limit - 3).concat('...') : value;
  }

}
