import { Inject, Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Token } from '../models/token';

import * as UserActions from '../actions/user.actions';
import { TOKEN } from '@/app/app.config';
import { BrowserTokenService } from '@/app/shared/user/services/browser-token.service';

@Injectable()
export class AuthenticateGuard implements CanLoad {

  constructor(
    private store: Store<any>,
    private actions: Actions,
    protected browserTokenService: BrowserTokenService,
    @Inject(TOKEN) private token: Token
  ) { }

  canLoad() {
    if (this.token && this.token.id && !this.browserTokenService.isTokenExpired(this.token)) {
      this.store.dispatch(new UserActions.Authenticate());
    } else {
      this.store.dispatch(new UserActions.AuthenticateAnonymous({ }));
    }

    return this.actions
      .pipe(
        ofType(UserActions.AUTHENTICATE_SUCCESS, UserActions.AUTHENTICATE_ERROR),
        first(),
        switchMap((action: UserActions.AuthenticateSuccess | UserActions.AuthenticateError) =>
          of(action.type === UserActions.AUTHENTICATE_SUCCESS))
      );
  }
}
