import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastType } from 'ui';
import { UserEffects } from './user.effects';

import * as UserActions from '../actions/user.actions';

@Injectable()
export class AuthorizeEffects extends UserEffects {

  @Effect()
  authorize: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHORIZE),
      switchMap((action: UserActions.Authorize) =>
        this.userService
          .authorize(action.payload.url)
          .pipe(
            map(response => new UserActions.AuthorizeComplete(response)),
            catchError(response => of(new UserActions.AuthorizeError(response)))
          )
      )
    );

  @Effect()
  authorizeComplete: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHORIZE_COMPLETE),
      switchMap((action: UserActions.AuthorizeComplete) => {
        if (!action.payload.allow) {
          return of(new UserActions.AuthorizeError({
            message: action.payload.message
          }));
        }

        return of(new UserActions.AuthorizeSuccess() as Action);
      })
    );

  @Effect()
  authorizeError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.AUTHORIZE_ERROR),
      tap((action: UserActions.AuthorizeError) => {
        this.toasterService.makeToast(action.payload.message, ToastType.Error);
      }),
      switchMap((action: UserActions.AuthorizeError) => {
        return [
          new UserActions.LoginRedirect({ url: '/aegisx/login' })
        ];
      })
    );

}

