import { Action } from '@ngrx/store';

import { Token } from '../models/token';
import { User } from '../models/user';
import { VariablesEshop } from '../../models/settings/variables-eshop';

export const AUTHENTICATE =           '[User] Authenticate';
export const AUTHENTICATE_ANONYMOUS = '[User] Authenticate Anonymous';
export const AUTHENTICATE_SUCCESS =   '[User] Authenticate Success';
export const AUTHENTICATE_ERROR =     '[User] Authenticate Error';

export const UPDATE_AUTHENTICATED =   '[User] UPDATE authenticated';

export class Authenticate implements Action {
  readonly type = AUTHENTICATE;

  constructor() { }
}

export class AuthenticateAnonymous implements Action {
  readonly type = AUTHENTICATE_ANONYMOUS;

  constructor(public payload: { afterLogout?: boolean }) { }
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(
    public payload: { user: User, token: Token, variables?: VariablesEshop },
    public afterLogout?: boolean
  ) { }
}

export class AuthenticateError implements Action {
  readonly type = AUTHENTICATE_ERROR;

  constructor(public payload: { message: string }) { }
}


export class UpdateAuthenticated implements Action {
  readonly type = UPDATE_AUTHENTICATED;

  constructor(public payload: { user: User, variables?: VariablesEshop }) { }
}


export type All
  = Authenticate
  | AuthenticateAnonymous
  | AuthenticateSuccess
  | AuthenticateError
  | UpdateAuthenticated;
