import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CharacterLimitPipe } from './pipes/character-limit.pipe';
import { NotSetPipe } from './pipes/not-set.pipe';
import { BooleanToStringPipe } from './pipes/boolean-to-string.pipe';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';

import { LoaderModule } from '../loader/loader.module';
import { MessageModule } from '../message/message.module';
import { McRAIButtonModule } from 'ui';
import { McRAIIconModule } from 'ui';
import { SafeHtmlDirective } from './directives/safe-html.directive';
import { LinkModule } from '../link/link.module';
import { ErrorModule } from '../error/error.module';
import { CharLimitWholeWordsPipe } from './pipes/char-limit-whole-words.pipe';

@NgModule({
  imports: [
    CommonModule,
    McRAIButtonModule,
    McRAIIconModule,
    RouterModule,
    LoaderModule,
    MessageModule,
    LinkModule,
    ErrorModule,
  ],
  declarations: [
    CharacterLimitPipe,
    NotSetPipe,
    BooleanToStringPipe,
    EnumToStringPipe,
    CharLimitWholeWordsPipe,
    SafeHtmlDirective,
  ],
  exports: [
    CharacterLimitPipe,
    CharLimitWholeWordsPipe,
    NotSetPipe,
    BooleanToStringPipe,
    EnumToStringPipe,
    LoaderModule,
    MessageModule,
    McRAIButtonModule,
    McRAIIconModule,
    SafeHtmlDirective,
    LinkModule
  ]
})
export class SharedModule { }
