import { Action } from '@ngrx/store';

import { Redirect } from '../../../../../shared/models/redirect/redirect';

export const GET_SHOP_REDIRECT         = '[Redirect] GET shop redirect';
export const GET_SHOP_REDIRECT_SUCCESS = '[Redirect] GET shop redirect success';

export class GetShopRedirect implements Action {
  readonly type = GET_SHOP_REDIRECT;

  constructor(public payload: { url: string }) { }
}

export class GetShopRedirectSuccess implements Action {
  readonly type = GET_SHOP_REDIRECT_SUCCESS;

  constructor(public payload: { item: Redirect }) { }
}

export type All
  = GetShopRedirect
  | GetShopRedirectSuccess;
