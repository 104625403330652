import * as HistoryActions from '../actions/get-search-history.actions';
import * as PostHistoryActions from '../../post-search-history/actions/post-search-history.actions';
import { SearchRecord } from '../../../../../shared/models/history/search-record';

export interface State {
  history: SearchRecord[];
  history_small: SearchRecord[];
  cnt_all: number;
}

export const initialState: State = {
  history: [],
  history_small: [],
  cnt_all: 0,
};

export const featureName = 'shopSearchHistory';

export function reducer(state = initialState, action: HistoryActions.All | PostHistoryActions.All) {
  switch (action.type) {
    case HistoryActions.GET_SEARCH_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload.history,
        cnt_all: action.payload.cnt_all,
      };
    case HistoryActions.GET_SEARCH_HISTORY_SMALL_SUCCESS:
      return {
        ...state,
        history_small: action.payload.history,
        cnt_all: action.payload.cnt_all,
      };
    // case PostHistoryActions.POST_SEARCH_HISTORY_SUCCESS:
    //   const new_history_small = state.history_small.slice(0, -1);
    //   new_history_small.unshift(action.payload.record);
    //   return {
    //     ...state,
    //     history_small: new_history_small,
    //   };
    default: {
      return state;
    }
  }
}

export const selectSearchHistory       = state => state[featureName].history;
export const selectSearchHistorySmall  = state => state[featureName].history_small;
export const selectSearchHistoryCntAll = state => state[featureName].cnt_all;
