import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { McRAILoader } from 'ui';
import { selectLoaderCount } from '../../reducers/loader.reducer';

@Component({
  selector: 'shared-loader-anchor',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, OnDestroy {
  private _destroy: Subject<null> = new Subject<null>();

  constructor(
    private store: Store<any>,
    private loaderService: McRAILoader
  ) { }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectLoaderCount),
        takeUntil(this._destroy),
        filter(value => value > 0)
      )
      .subscribe((value) => {
        this._onCountChange(value);
      });

    this.store
      .pipe(
        select(selectLoaderCount),
        filter(value => value <= 0),
        debounceTime(400),
        takeUntil(this._destroy)
      )
      .subscribe((value) => {
        this._onCountChange(value);
      });

  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private _onCountChange(count: number): void {
    if (count > 0) {
      this.loaderService.show();
    } else {
      this.loaderService.hide();
    }
  }

}
