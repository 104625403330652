import { Action } from '@ngrx/store';

import { Heatmap } from '../../../../../shared/models/admin-panel/heatmap';

export const GET_CLICKS         = '[Heatmap] GET clicks';
export const GET_CLICKS_SUCCESS = '[Heatmap] GET clicks success';
export const GET_CLICKS_ERROR   = '[Heatmap] GET clicks error';

export class GetClicks implements Action {
  readonly type = GET_CLICKS;

  constructor(public payload: { page: string }) { }
}

export class GetClicksSuccess implements Action {
  readonly type = GET_CLICKS_SUCCESS;

  constructor(public payload: Heatmap) { }
}

export class GetClicksError implements Action {
  readonly type = GET_CLICKS_ERROR;

  constructor(public payload: { message: string }) { }
}

export type All
 = GetClicks
 | GetClicksSuccess
 | GetClicksError;
