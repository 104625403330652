import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { selectAuthenticated } from '../reducers/user.reducer';
import { User } from '../models/user';
import { PagePermission } from '../../models/permissions/page-permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements OnInit, OnDestroy {
  destroy: Subject<any> = new Subject();
  user: User;

  readonly READ = 1;
  readonly READ_WRITE = 2;

  constructor(
    private store: Store<any>,
    private router: Router,
  ) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.store.select(selectAuthenticated)
      .pipe(takeUntil(this.destroy))
      .subscribe((user: User) => this.user = user);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public canWrite(user_id?: string): boolean {
    return user_id ?
      this.canWritePageByUser(this.parseUrl(this.router.url), user_id)
      : this.canWritePage(this.parseUrl(this.router.url));
  }

  public canExport(): boolean {
    return this.canWritePage('aegisx.export');
  }

  // noinspection JSMethodCanBeStatic
  private parseUrl(url: string): string {
    url = url.replace(/^\/+|\/$/, '');
    url = url.replace(/\w*([a-z]*[0-9]+)+\w*$/, ':id');
    url = url.replace(/\//g, '.');

    return url;
  }

  public canReadPage(page_id: string): boolean {
    return this.user && this._canRead(page_id);
  }

  public canWritePage(page_id: string): boolean {
    return this.user && this._canWrite(page_id);
  }

  public canWritePageByUser(page_id: string, user_id: string): boolean {
    return this.user && this.user.id === user_id && this._canWrite(page_id);
  }

  public isDiscountFixed(): boolean {
    return this.user.active_domain.recalc_moc;
  }

  private _canRead(page_id: string): boolean {
    return !!this.user.permissions.find(
      (_permission: PagePermission) => _permission.id === page_id && _permission.permission >= this.READ
    );
  }

  private _canWrite(page_id: string): boolean {
    return !!this.user.permissions.find(
      (_permission: PagePermission) => _permission.id === page_id && _permission.permission >= this.READ_WRITE
    );
  }

}
