import { Action } from '@ngrx/store';

import { User } from '../models/user';
import { Token } from '../models/token';
import { RegistrationBody } from '../models/registration-body';
import { Permission } from '../models/permission';
import { McRAIModal } from 'ui';
import { ErrorGui } from '../../models/errors/error-gui';

export const REGISTRATION          = '[User] Registration';
export const REGISTRATION_SUCCESS  = '[User] Registration Success';
export const REGISTRATION_ERROR    = '[User] Registration Error';

export class Registration implements Action {
  readonly type = REGISTRATION;

  constructor(public payload: {
    body: RegistrationBody, loginModalService?: McRAIModal
  }) { }
}

export class RegistrationSuccess implements Action {
  readonly type = REGISTRATION_SUCCESS;

  constructor(
    public payload: {
      user: User, token: Token, message: string, permissions: Permission[]
    },
    public loginModalService?: McRAIModal
  ) { }
}

export class RegistrationError implements Action {
  readonly type = REGISTRATION_ERROR;

  constructor(public payload: ErrorGui) { }
}
