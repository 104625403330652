import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { RootComponent } from './components/root/root.component';

import { McRAIToasterModule } from 'ui';

import * as fromMessage from './reducers/message.reducer';

@NgModule({
  imports: [
    CommonModule,
    McRAIToasterModule,
    StoreModule.forFeature('sharedMessage', fromMessage.reducer)
  ],
  declarations: [
    RootComponent
  ],
  exports: [
    RootComponent
  ]
})
export class MessageModule { }
