import { Action } from '@ngrx/store';
import { ErrorGui } from '../../models/errors/error-gui';
import { MessagesResponseGui } from '../../base/messages-response-gui';

export const ERROR_ACTION = 'ERROR';
export const ERROR_GUI_ACTION = 'ERROR GUI';

export class ErrorAction implements Action {
  readonly type = ERROR_ACTION;

  constructor(public payload: MessagesResponseGui, public duration?: number) { }
}

export class ErrorGuiAction implements Action {
  readonly type = ERROR_GUI_ACTION;

  constructor(public payload: ErrorGui, public duration?: number) { }
}

export type All
  = ErrorAction
  | ErrorGuiAction;
