import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserEffects } from './user.effects';
import { ToastType } from 'ui';

import * as ExportsActions from '../actions/exports.actions';
import { UnconfirmedExportsModalComponent } from '../components/unconfirmed-exports-modal/unconfirmed-exports-modal.component';

@Injectable()
export class ExportsEffects extends UserEffects {

  @Effect()
  getHasUnconfirmedExports: Observable<Action> = this.actions
    .pipe(
      ofType(ExportsActions.GET_HAS_UNCONFIRMED_EXPORTS),
      switchMap((action: ExportsActions.GetHasUnconfirmedExports) =>
        this.userService
          .getHasUnconfirmedExports()
          .pipe(
            map(response => new ExportsActions.GetHasUnconfirmedExportsSuccess(response, action.displayType)),
            catchError(response => of(new ExportsActions.GetHasUnconfirmedExportsError(response)))
          )
      )
    );

  @Effect({ dispatch: false })
  getHasUnconfirmedExportsSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(ExportsActions.GET_HAS_UNCONFIRMED_EXPORTS_SUCCESS),
      tap((action: ExportsActions.GetHasUnconfirmedExportsSuccess) => {
        if (!action.payload.has_unconfirmed) {
          return;
        }

        const link = this.linkService.getShopExportsAbsoluteUrl();
        const text = 'Máte nepotvrzené výdejky, abyste mohli dále objednávat, je nutné ' +
          '<a href="' + link + '">výdejky odsouhlasit</a>.';

        this.processDisplayAction(
          action,
          text,
          'Nepotvrzené výdejky',
          UnconfirmedExportsModalComponent
        );
      })
    );

  @Effect({ dispatch: false })
  getHasUnconfirmedExportsError: Observable<Action> = this.actions
    .pipe(
      ofType(ExportsActions.GET_HAS_UNCONFIRMED_EXPORTS_ERROR),
      tap((action: ExportsActions.GetHasUnconfirmedExportsError) => {
        this.toasterService.makeToast(action.payload.error.message, ToastType.Error);
      })
    );
}
