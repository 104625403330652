import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GetSearchHistorySmall } from '../../actions/get-search-history.actions';
import { SearchRecord } from '../../../../../../shared/models/history/search-record';
import { selectSearchHistorySmall } from '../../reducers/get-search-history.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkService } from '../../../../../../shared/link/services/link.service';
import { RecordList } from '../../../models/record-list';

@Component({
  selector: 'shop-search-record-list-small',
  templateUrl: './search-record-list-small.component.html',
  styleUrls: ['./search-record-list-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class SearchRecordListSmallComponent extends RecordList implements OnInit {
  records: Observable<SearchRecord[]>;

  constructor(
    protected store: Store<any>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected linkService: LinkService,
  ) {
    super(store, activatedRoute, router);
  }

  ngOnInit(): void {
    this.records = this.store
      .pipe(
        select(selectSearchHistorySmall)
      );

    this.fetchHistory();
  }

  protected fetchHistory(): void {
    this.store.dispatch(new GetSearchHistorySmall({
      ipp: 5,
    }));
  }

  public buildLink(query: string): string {
    return this.linkService.getSearchLink() + '?q=' + query;
  }

}
