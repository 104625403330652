import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ToastType } from 'ui';

import { UserEffects } from './user.effects';
import { AuthenticateSuccess } from '../actions/user.actions';

import * as UserActions from '../actions/user.actions';

@Injectable()
export class RegisterEffects extends UserEffects {

  @Effect()
  register: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.REGISTRATION),
      tap(() => {
        this.loaderService.show();
      }),
      switchMap((action: UserActions.Registration) =>
        this.userService
          .register(action.payload.body)
          .pipe(
            map(response => new UserActions.RegistrationSuccess(
              response, action.payload.loginModalService
            )),
            catchError(response => of(new UserActions.RegistrationError(response)))
          )
      )
    );

  @Effect()
  registerSuccess: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.REGISTRATION_SUCCESS),
      tap((action: UserActions.RegistrationSuccess) => {
        this.toasterService.makeToast(action.payload.message, ToastType.Success);
        this.modalService.close();
      }),
      switchMap((action: UserActions.RegistrationSuccess) => {
        if (action.loginModalService) {
          action.loginModalService.close();
        }
        return of(new AuthenticateSuccess(action.payload));
      })
    );

  @Effect({ dispatch: false })
  registerError: Observable<Action> = this.actions
    .pipe(
      ofType(UserActions.REGISTRATION_ERROR),
      tap((action: UserActions.RegistrationError) => {
        this.toasterService.makeToast(action.payload.error.message, ToastType.Error);
        this.loaderService.hide();
      })
    );

}

