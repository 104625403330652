import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { McRAILoaderModule, McRAIModalModule } from 'ui';
import { McRAIToasterModule } from 'ui';

@NgModule({
  imports: [
    CommonModule,
    McRAIToasterModule,
    McRAILoaderModule,
    McRAIModalModule
  ],
  declarations: []
})
export class BaseHistoryModule { }
