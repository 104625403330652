import { Action } from '@ngrx/store';

export const AUTHORIZE          = '[User] Authorize';
export const AUTHORIZE_COMPLETE = '[User] Authorize Complete';
export const AUTHORIZE_SUCCESS  = '[User] Authorize Success';
export const AUTHORIZE_ERROR    = '[User] Authorize Error';

export class Authorize implements Action {
  readonly type = AUTHORIZE;

  constructor(public payload: { url: string } ) { }
}

export class AuthorizeComplete implements Action {
  readonly type = AUTHORIZE_COMPLETE;

  constructor(public payload: { allow: boolean, message?: string }) { }
}

export class AuthorizeSuccess implements Action {
  readonly type = AUTHORIZE_SUCCESS;

  constructor() { }
}

export class AuthorizeError implements Action {
  readonly type = AUTHORIZE_ERROR;

  constructor(public payload: { message: string }) { }
}
