import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { McRAIToaster } from 'ui';
import { McRAILoader2 } from 'ui';
import * as ErrorActions from '../actions';
import { ToastType } from 'ui';
import { MessagesResponseGui } from '../../base/messages-response-gui';

@Injectable()
export class ErrorEffects {

  constructor(
    protected actions: Actions,
    protected toastService: McRAIToaster,
    protected loaderService: McRAILoader2
  ) { }

  @Effect({ dispatch: false })
  error: Observable<Action> = this.actions
    .pipe(
      ofType(ErrorActions.ERROR_ACTION),
      tap((action: ErrorActions.ErrorAction) => this.processError(
        action.payload, action.duration
      ))
    );

  @Effect({ dispatch: false })
  errorGui: Observable<Action> = this.actions
    .pipe(
      ofType(ErrorActions.ERROR_GUI_ACTION),
      tap((action: ErrorActions.ErrorGuiAction) => this.processError(
        action.payload.error, action.duration
      ))
    );

  private processError(error: MessagesResponseGui, duration?: number): void {
    const msgs: string[] = error.messages || [];
    if (error.message) {
      msgs.push(error.message);
    }

    msgs.forEach(
      (msg: string) => this.toastService.makeToast(msg, ToastType.Error, duration)
    );

    this.loaderService.hide();
  }
}
