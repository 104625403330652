import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Heatmap } from '../../../../../shared/models/admin-panel/heatmap';

import * as ModuleActions from '../actions/get-clicks.actions';

export const adapter: EntityAdapter<Heatmap> = createEntityAdapter<Heatmap>({
  selectId: model => model.page
});

export interface State extends EntityState<Heatmap> { }

export const initialState: State = adapter.getInitialState();

export const featureName = 'shopHeatmap';

export function reducer(state = initialState, action: ModuleActions.All) {
  switch (action.type) {
    case ModuleActions.GET_CLICKS_SUCCESS:
      return adapter.upsertOne(action.payload, state);
    default: {
      return state;
    }
  }
}

export const state = createFeatureSelector<State>(featureName);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectHeatmapIds = createSelector(state, selectIds);
export const selectHeatmapEntities = createSelector(state, selectEntities);
export const selectHeatmapAll = createSelector(state, selectAll);
export const selectHeatmapTotal = createSelector(state, selectTotal);
