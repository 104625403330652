import { Credentials } from '../models/credentials';
import { User } from '../models/user';

import * as UserActions from '../actions/user.actions';
import { PagePermission } from '../../models/permissions/page-permission';
import { VariablesEshop } from '../../models/settings/variables-eshop';

export interface State {
  credentials: Credentials;
  authenticated: User;
  variables: VariablesEshop;
  isAuthorized: boolean;
  has_unconfirmed_exports: boolean;
}

export const initialState: State = {
  credentials: null,
  authenticated: null,
  variables: null,
  isAuthorized: false,
  has_unconfirmed_exports: false,
};

export const featureName = 'sharedUser';

export function isAdmin(user: User): boolean {
  return user.role.permissions.find(
    (pp: PagePermission): boolean => /^aegisx\./.test(pp.id)
  ) !== undefined;
}

export function reducer(state = initialState, action: UserActions.All): State {
  switch (action.type) {
    case UserActions.LOGIN:
      return {
        ...state,
        credentials: action.payload.credentials,
      };

    case UserActions.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: action.payload.user,
        isAuthorized: true,
      };

    case UserActions.ADMIN_LOGIN_SUCCESS:
      if (isAdmin(action.payload.user)) {
        return {
          ...state,
          authenticated: action.payload.user,
          isAuthorized: true,
        };
      } else {
        return state;
      }

    case UserActions.AUTHENTICATE_SUCCESS:
    case UserActions.UPDATE_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload.user,
        variables: action.payload.variables,
      };

    case UserActions.AUTHENTICATE_ERROR:
      return {
        ...state,
        authenticated: null,
      };

    case UserActions.AUTHORIZE_COMPLETE:
      return {
        ...state,
        isAuthorized: action.payload.allow,
      };

    case UserActions.AUTHORIZE_ERROR:
      return {
        ...state,
        isAuthorized: false,
      };

    case UserActions.LOGOUT_SUCCESS:
      return initialState;

    case UserActions.GET_HAS_UNCONFIRMED_EXPORTS_SUCCESS:
      return {
        ...state,
        has_unconfirmed_exports: action.payload.has_unconfirmed,
      };

    default: {
      return state;
    }
  }
}

export const selectAuthenticated = state => state[featureName].authenticated;
export const selectIsAnon = state => !state[featureName].authenticated || state[featureName].authenticated.anonym;
export const selectVariables = state => state[featureName].variables;
export const selectHasUnconfirmedExports = state => state[featureName].has_unconfirmed_exports;
