import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AssocArray } from '../../../../shared/models/base/assoc-array';
import { FormControl, FormGroup } from '@angular/forms';
import { OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Record } from '../../../../shared/models/history/record';

export abstract class RecordList implements OnInit, OnDestroy {
  destroy: Subject<null> = new Subject<null>();

  records: Observable<Record[]>;
  cnt_all: Observable<number>;

  order_by: string = 'datetime';
  order: string = 'desc';
  search: string = '';

  form: FormGroup = new FormGroup({
    search: new FormControl(),
  });

  protected constructor(
    protected store: Store<any>,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
  ) { }

  ngOnInit(): void {
    this.form.controls.search.valueChanges
      .pipe(
        takeUntil(this.destroy),
        debounceTime(1000),
      )
      .subscribe((search: string) => {
        this.search = search;
        this.fetchHistory(this.buildFilter());
      });

    this.fetchHistory();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  protected abstract fetchHistory(filter?: AssocArray): void;

  public formatDatetime(datetime: string): string {
    const dt_parts = datetime.split(' ');
    const date_str = dt_parts[0];
    const time_str = dt_parts[1];
    const today = new Date();
    const today_str = [
      today.getDate(), today.getMonth() + 1, today.getFullYear()
    ].join('.');

    if (date_str === today_str) {
      const time_parts = time_str.split(':');
      time_parts.splice(2);
      return time_parts.join(':');
    } else {
      const date_parts = date_str.split('.');
      date_parts[2].slice(2);
      return date_parts.join('.');
    }
  }

  public linkOnClick(event: MouseEvent, target: string, params?: AssocArray): void {
    event.preventDefault();
    this.redirect(target, params);
  }

  public redirect(target: string, params?: AssocArray): void {
    const extras: AssocArray = {
      relativeTo: this.activatedRoute,
    };

    if (params) {
      extras.queryParams = params;
    }

    this.router.navigate([target], extras);
  }

  public orderBy(col: string): void {
    if (this.order_by !== col || (this.order_by === col && this.order === 'desc')) {
      this.order = 'asc';
    } else {
      this.order = 'desc';
    }

    this.order_by = col;

    this.fetchHistory(this.buildFilter());
  }

  protected buildFilter(): AssocArray {
    const filter: AssocArray = {
      order_by: this.order_by,
      order: this.order,
    };

    if (this.search) {
      filter.q = this.search;
    }

    return filter;
  }

}
