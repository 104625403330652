import { Injectable } from '@angular/core';
import { Logger } from '../../models/logger/logger';
import { LoggBannersRequest } from '../../models/logger/requests/logg-banners-request';
import { LoggItem } from '../../models/logger/types/logg-item';
import { LoggLoadRequest } from '../../models/logger/requests/logg-load-request';
import { LoggOrderRequest } from '../../models/logger/requests/logg-order-request';
import { LoggViewRequest } from '../../models/logger/requests/logg-view-request';

declare let logger: Logger;

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements Logger {

  private static checkLogger(): boolean {
    return typeof logger !== 'undefined';
  }

  public view(data?: LoggViewRequest): this {
    if (LoggerService.checkLogger()) {
      logger.view(data);
    }

    return this;
  }

  public order(data: LoggOrderRequest): this {
    if (LoggerService.checkLogger()) {
      logger.order(data);
    }

    return this;
  }

  public load(params: LoggLoadRequest, callback?: (json_response: string) => void): this {
    if (LoggerService.checkLogger()) {
      logger.load(params, callback);
    }

    return this;
  }

  public banners(params?: LoggBannersRequest, callback?: (json_response: string) => void): this {
    if (LoggerService.checkLogger()) {
      logger.banners(params, callback);
    }

    return this;
  }

  public cart(data: LoggItem[], callback?: (json_response: string) => void): this {
    if (LoggerService.checkLogger()) {
      logger.cart(data, callback);
    }

    return this;
  }

}
