import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { McRAILoader2 } from 'ui';
import { McRAIToaster } from 'ui';
import { DataService } from '../services/data.service';
import { McRAIModal } from 'ui';

@Injectable()
export class BaseUserSectionEffects {
  constructor(
    protected actions: Actions,
    protected dataService: DataService,
    protected store: Store<any>,
    protected loaderService: McRAILoader2,
    protected toasterService: McRAIToaster,
    protected modalService: McRAIModal
  ) { }
}
