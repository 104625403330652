import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../../../../shared/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {

  public postClick(page: string, point: [number, number, number]): Observable<{ points: [number, number, number][] }> {
    const endpoint = this.buildUrl(['shop', 'logger', 'click']);
    const body = {
      page: page,
      x: point[0],
      y: point[1],
    };

    return this.post(endpoint, body);
  }

  public getPoints(page: string): Observable<{ points: [number, number, number][] }> {
    const endpoint = this.buildUrl(['logger', 'clicks'], { page: page });

    return this.get(endpoint);
  }

}
